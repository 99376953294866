import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ApiService } from "src/app/services/api/api.service";
import { video } from "./video.model";

@Injectable({
    providedIn: 'root'
})
export class VideoUploadBLL {

    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLoading$: Observable<boolean> = this.isLoading.asObservable();

    private videoCreated: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public videoCreated$: Observable<number> = this.videoCreated.asObservable();

    private isVideosLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isVideosLoading$: Observable<boolean> = this.isVideosLoading.asObservable();

    constructor(
        private api: ApiService
    ){}

    public generateUploadVideoURL(module: string, user_id: string): Promise<any>{
        let url = `video-management?action=generate_upload_url&user_id=${user_id}&module=${module}`;
        return this.api.get(url).toPromise();
    }

    public createVideo(video: video){
        let url = `video-management`;
        this.isLoading.next(true);
        this.api.post(url, video).pipe(take(1)).subscribe(res => {
            this.videoCreated.next(1);
            this.isLoading.next(false);
        }, err => {
            console.log(err);
        });
    }

    public getPostProcessingStatus(video: video): Promise<any>{
        let url = `video-management?action=pollPostProcessing&video_id=${video.video_id}`;
        return this.api.get(url).toPromise();
    }

    public createVideoInDB(video: video, postOnSocial: boolean): Promise<any>{
        let url = `video-management?postOnSocial=${postOnSocial}`;
        return this.api.post(url, video).toPromise();
    }

    public resetObservables(){
        this.isLoading.next(false);
        this.videoCreated.next(null);
        this.isVideosLoading.next(false);
    }
}