
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { fromEvent, Observable } from 'rxjs';
import { createMask } from '@ngneat/input-mask';
import { debounceTime, distinctUntilChanged, filter, skipWhile, take, tap } from 'rxjs/operators';
import { workout_list } from 'src/app/components/calendar/calendar.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { QuickWorkoutsBLL } from 'src/app/views/quick-workout/quick-workout.bll';
import { ActionSessionWorkouts } from 'src/app/views/home/components/active-session/active-session.workouts';

@Component({
  selector: 'app-new-quick-workout',
  templateUrl: './new-quick-workout.page.html',
  styleUrls: ['./new-quick-workout.page.scss'],
})
export class NewQuickWorkoutPage implements OnInit, AfterViewInit {

  @ViewChild('search_workout') search_workout_element: ElementRef;

  is_loading$: Observable<boolean>;
  is_workout_loading$: Observable<boolean>;
  workout_created$: Observable<boolean>;
  all_workout_sub_categories$: Observable<workout_list[]>;
  recent_sub_categories$: Observable<workout_list[]>;
  favourite_sub_categories$: Observable<workout_list[]>;

  show_add_workout$: Observable<boolean>;
  add_workout_overlay: boolean = true;
  add_title_overlay: boolean = true;

  add_excersize_overlay: boolean = false;
  view_workout_summary = false;
  selected_workout: any;

  selected_category = 'recents';

  user: any = {};

  //form
  data = {
    user_workout: {
      title: '',
      description: ''
    },
    workouts: [],
    uom: 'imperial',
    game_highlight: false
  }

  //InputMask for Time
  timeInputMask = createMask<string>({
    inputType: 'number',
    mask: '99:99:99.99',
    placeholder: '_',
    onBeforeMask: (value: string) => {
      const values = value.split(':');
      const minutes = values[1];
      const seconds = values[2];
      return `${minutes}:${seconds}`;
    },
    parser: (value: string) => {
      const minutes = value.split(':')[1];
      const seconds = value.split(':')[2];
      const hours = value.split(':')[0];
      return `${Number(hours) ? Number(hours) + parseInt((Number(minutes) / 60).toString()) : 0 + parseInt((Number(minutes) / 60).toString())}:${Number(minutes) % 60 + parseInt((Number(seconds) / 60).toString())}:${Number(seconds) % 60}`;
    }
  });

  //Distance Input Mask for Distance in ft and inches
  distanceInputMask = createMask<string>({
    inputType: 'number',
    mask: '99 ft 99 in',
    placeholder: '--',
    positionCaretOnClick: 'select',
    onBeforeMask: (value: string) => {
      const values = value.split(' ');
      const ft = values[0];
      const inches = values[2];
      return `${ft} ft ${inches} in`;
    },
    parser: (value: string) => {
      const ft = value.split(' ')[0];
      const inches = value.split(' ')[2];
      return `${Number(ft) && (Number(ft) * 12) + (Number(inches))}`;
    }
  });

  async showTimeError(message) {
    let t = await this.toast.create({
      animated: true,
      color: 'warning',
      message: message,
      duration: 2000
    });
    t.present();
  }

  constructor(
    private modal: ModalController,
    private bll: QuickWorkoutsBLL,
    private workout_service: ActionSessionWorkouts,
    private toast: ToastController,
    private auth: AuthService
  ) { }

  ngAfterViewInit(): void {
    this.map_observables();
    fromEvent(this.search_workout_element.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((text) => {
          this.search_workouts(this.search_workout_element.nativeElement.value);
        })).subscribe();
  }

  ngOnInit() {
    this.auth.auth$
      .pipe(
        skipWhile((val) => !val.token),
        take(1)
      )
      .subscribe(async (user) => {
        this.user = user;
        this.bll.get_all_workouts('');
      });
  }

  isAciSearch() {
    let aciKey = this.search_workout_element?.nativeElement.value.toLowerCase();
    return aciKey === 'aci';
  }

  map_observables() {
    this.is_loading$ = this.bll.is_loading$;
    this.show_add_workout$ = this.bll.show_add_workout$;
    this.workout_created$ = this.bll.workout_created$;
    this.is_workout_loading$ = this.bll.is_search_workouts_loading$;
    this.all_workout_sub_categories$ = this.bll.all_workout_sub_categories$;
    this.favourite_sub_categories$ = this.bll.favourite_sub_categories$;
    this.recent_sub_categories$ = this.bll.recent_sub_categories$;

    this.workout_created$.pipe(skipWhile(val => !val)).subscribe((wc) => {
      if (wc) {
        this.bll.clear_search_workout();
        this.bll.clear_observables();
        this.show_success();
        this.modal.dismiss({
          created: true
        });
      }
    });
  }

  selectAci(workouts) {
    workouts.forEach(w => {
      if (w.category_name === "ACI workouts") {
        this.select_workout(w)
      }
    });
  }

  async close_modal() {
    await this.modal.dismiss();
    this.resset_component();
  }

  resset_component() {
    this.add_title_overlay = true;
  }



  search_workouts(search: string) {
    this.bll.get_all_workouts(search);
  }

  // select_workout(w) {
  //   let workout = this.workout_service.generate_single_workout(w);
  //   this.data.workouts.push(workout);
  //   this.add_workout_overlay = false;
  //   this.search_workout_element.nativeElement.value = '';
  //   this.bll.clear_search_workout();
  // }

  close_search_workout() {
    this.search_workout_element.nativeElement.value = '';
    this.add_workout_overlay = false;
    this.bll.clear_search_workout();
  }

  add_set_group_to_workout(sub_category_id: string) {
    // let i = this.data.workouts.findIndex(x => x.sub_category_id === sub_category_id);
    // this.data.workouts[i].value.push(this.workout_service.generate_object_by_matrix(this.data.workouts[i].matrix));
    this.current_generated_workout.value.push(this.workout_service.generate_object_by_matrix(this.current_generated_workout.matrix));
  }

  remove_set_group_from_workout(id: string, sub_category_id: string) {
    // let i = this.data.workouts.findIndex(x => x.sub_category_id === sub_category_id);
    this.current_generated_workout.value = this.current_generated_workout.value.filter((v) => { return v._id !== id });
  }

  async submit() {
    // if (!this.data.user_workout.title) {
    //   let t = await this.toast.create({
    //     animated: true,
    //     color: 'warning',
    //     message: 'Please fill in the title for this workout',
    //     duration: 2000
    //   });
    //   t.present();
    //   return;
    // }

    // if (this.data.workouts.length < 1) {
    //   let t = await this.toast.create({
    //     animated: true,
    //     color: 'warning',
    //     message: 'Please add at least one workout to proceed',
    //     duration: 3000
    //   });
    //   t.present();
    //   return;
    // }

    if (this.data.user_workout.title && this.data.workouts.length > 0) {
      this.bll.post_workout(this.data, this.user.id);
    }
  }

  async show_success() {
    let t = await this.toast.create({
      animated: true,
      color: 'success',
      message: 'Workout added successfully',
      duration: 3000
    });
    t.present();
    this.close_add_menu();
  }

  current_generated_workout: any;

  // already in use starts

  select_workout(w, type = '') {
    let workout = this.workout_service.generate_single_workout(w);
    // this.data.workouts.push(workout);
    this.current_generated_workout = workout;
    if (type == 'favorite') {
      this.current_generated_workout.favorite = true;
    }
    if (w.game_highlight.length > 0) {
      this.current_generated_workout.favorite = true;
    }
    // this.add_workout_overlay = false;
    // this.search_workout_element.nativeElement.value = '';
    // this.bll.clear_search_workout();
  }

  change_uom() {
    if (this.data.uom === 'imperial') {
      this.data.uom = 'metric';
    } else if (this.data.uom === 'metric') {
      this.data.uom = 'imperial';
    }
  }

  // new changes strts 

  deleteWorkout(w) {
    this.data.workouts = this.data.workouts.filter(wd => wd.sub_category_id != w.sub_category_id);
  }

  edit_workout = false;

  editWorkout(w) {
    console.log("workou", w)
    this.view_workout_summary =  false;
    this.add_excersize_overlay = true;
    this.current_generated_workout = w;
    this.selected_workout = w;
    this.edit_workout = true;
  }

  editWorkoutFavorite(w) {
    w.favorite = !w.favorite;
  }

  unitOfMeasurements(w) {
    return w.unit_of_measurement ? ''+w?.unit_of_measurement?.split(', ')[w?.unit_of_measurement?.split(',')?.length - 1]+'' : ''
  }

  convertToInches(feet, unit) {
    if(unit === 'Feet & Inches') {
      return Math.trunc(feet/12) + ' ft ' + (feet)%12 + ' in';
    } else {
      return feet + " " + unit;
    }
  }

  // markFavorite(fav) {
  //   // this.data.game_highlight = fav;
  // }
  
  openViewWorkout() {
    this.view_workout_summary = true;
  }

  closeViewWorkout() {
    this.view_workout_summary = false;
  }

  async proceed_to_add() {
    if (!this.data.user_workout.title) {
      let t = await this.toast.create({
        animated: true,
        color: 'warning',
        message: 'Please fill in the title for this workout',
        duration: 2000
      });
      t.present();
      return;
    }
    this.toggleOverlay();
  }

  add_workout(w, name, type = '') {
    console.log("w", w)
    this.add_excersize_overlay = true;
    if (name) {
      w.category_name = name;
    }
    this.selected_workout = w;
    this.select_workout(w, type);
  }

  async update_to_workout() {
    let matrix = this.current_generated_workout.matrix;
    let sort_by = '';

    switch (matrix) {
      case "T":
        sort_by = sort_by ? sort_by : 'time';
        break;
      case "S,R,W":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "SP":
        sort_by = sort_by ? sort_by : 'speed';
        break;
      case "D":
        sort_by = sort_by ? sort_by : 'distance';
      case "S,R":
        sort_by = sort_by ? sort_by : 'reps';
        break;
      case "P":
        sort_by = sort_by ? sort_by : 'percentage';
        break;
      case "H":
        sort_by = sort_by ? sort_by : 'height';
        break;
      case "PO":
        sort_by = sort_by ? sort_by : 'points';
        break;
      case "S,R,W,H":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "R,W":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "W,D":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "W":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "S,R,W,D":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "S,R,H":
        sort_by = sort_by ? sort_by : 'height';
        break;
      case "T,W":
        sort_by = sort_by ? sort_by : 'time';
        break;
      case "S":
        sort_by = sort_by ? sort_by : 'sets';
        break;
      case "R":
        sort_by = sort_by ? sort_by : 'reps';
        break;
      case "T,D":
        sort_by = sort_by ? sort_by : 'distance';
        break;
      case "S,T":
        sort_by = sort_by ? sort_by : 'time';
        break;
      case "S,D,W":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "S,R,T":
        sort_by = sort_by ? sort_by : 'time';
        break;
    };

    let emptyValues = this.current_generated_workout.value.filter(val => (!val[sort_by] || val[sort_by]?.toString()?.includes('NaN')));

    if (matrix && emptyValues.length > 0) {
      let t = await this.toast.create({
        animated: true,
        color: 'warning',
        message: `Please fill in the required data for this workout`,
        duration: 2000
      });
      t.present();
      return;
    } else {
      let t = await this.toast.create({
        animated: true,
        color: 'success',
        message: `Workout updated successfully.`,
        duration: 2000
      });
      t.present();
    }
    // this.data.workouts.push(this.current_generated_workout);
    this.close_workout();
  }

  async add_to_workout_fav_toggle(fav = 'not') {
    if (fav == 'fav') {
      // this.markFavorite(true);
      this.current_generated_workout.favorite = true;
    } 
    if (fav == 'remove') {
      this.current_generated_workout.favorite = false;
    }
  }

  async add_to_workout(fav = 'not') {
    // if (fav == 'fav') {
    //   // this.markFavorite(true);
    //   this.current_generated_workout.favorite = true;
    // } 
    // if (fav == 'remove') {
    //   this.current_generated_workout.favorite = false;
    // }
    // else {
    //   this.current_generated_workout.favorite = false;
    // }

    let matrix = this.current_generated_workout.matrix;
    let sort_by = '';

    switch (matrix) {
      case "T":
        sort_by = sort_by ? sort_by : 'time';
        break;
      case "S,R,W":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "SP":
        sort_by = sort_by ? sort_by : 'speed';
        break;
      case "D":
        sort_by = sort_by ? sort_by : 'distance';
      case "S,R":
        sort_by = sort_by ? sort_by : 'reps';
        break;
      case "P":
        sort_by = sort_by ? sort_by : 'percentage';
        break;
      case "H":
        sort_by = sort_by ? sort_by : 'height';
        break;
      case "PO":
        sort_by = sort_by ? sort_by : 'points';
        break;
      case "S,R,W,H":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "R,W":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "W,D":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "W":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "S,R,W,D":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "S,R,H":
        sort_by = sort_by ? sort_by : 'height';
        break;
      case "T,W":
        sort_by = sort_by ? sort_by : 'time';
        break;
      case "S":
        sort_by = sort_by ? sort_by : 'sets';
        break;
      case "R":
        sort_by = sort_by ? sort_by : 'reps';
        break;
      case "T,D":
        sort_by = sort_by ? sort_by : 'distance';
        break;
      case "S,T":
        sort_by = sort_by ? sort_by : 'time';
        break;
      case "S,D,W":
        sort_by = sort_by ? sort_by : 'weight';
        break;
      case "S,R,T":
        sort_by = sort_by ? sort_by : 'time';
        break;
    };
    console.log("inc", this.current_generated_workout.value )
    let emptyValues = this.current_generated_workout.value.filter(val => (!val[sort_by] || val[sort_by]?.toString()?.includes('NaN')));

    if (matrix && emptyValues.length > 0) {
      let t = await this.toast.create({
        animated: true,
        color: 'warning',
        message: `Please fill in the ${sort_by} for this workout`,
        duration: 2000
      });
      t.present();
      return;
    }
    this.data.workouts.push(this.current_generated_workout);
    this.close_workout();
  }

  close_workout() {
    this.add_excersize_overlay = false;
    this.selected_workout = {};
    this.edit_workout = false;
  }

  close_add_menu() {
    this.bll.toggle_add_menu(false);
  }

  toggleOverlay() {
    this.add_title_overlay = !this.add_title_overlay;
  }

  edit_title() {
    this.add_title_overlay = true;
  }

  select_category_filter(category_name, id) {
    this.selected_category = category_name;

    this.scrollToElement(id);
  }

  clear_search() {
    this.search_workout_element.nativeElement.value = '';
    // this.bll.clear_search_workout();
    this.search_workouts(this.search_workout_element.nativeElement.value);
  }

  scrollToElement(elementId: string): void {
    if (elementId == 'newQuickWorkoutTop') {
      this.selected_category = 'recents';
    }

    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

}
