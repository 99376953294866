import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import moment from 'moment';
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { workout_list } from "src/app/components/calendar/calendar.model";
import { ApiService } from "src/app/services/api/api.service";

@Injectable({
    providedIn: 'root'
})
export class QuickWorkoutsBLL {

    private is_loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly is_loading$: Observable<boolean> = this.is_loading.asObservable();

    private is_search_workouts_loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly is_search_workouts_loading$: Observable<boolean> = this.is_search_workouts_loading.asObservable();

    private workout_created: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly workout_created$: Observable<boolean> = this.workout_created.asObservable();

    private workout_sub_categories: BehaviorSubject<workout_list[]> = new BehaviorSubject<workout_list[]>([]);
    readonly workout_sub_categories$: Observable<workout_list[]> = this.workout_sub_categories.asObservable();
    
    private verifier_verification: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    readonly verifier_verification$: Observable<any> = this.verifier_verification.asObservable();
    
    
    private all_workout_sub_categories: BehaviorSubject<workout_list[]> = new BehaviorSubject<workout_list[]>([]);
    readonly all_workout_sub_categories$: Observable<workout_list[]> = this.all_workout_sub_categories.asObservable();
    

    private recent_sub_categories: BehaviorSubject<workout_list[]> = new BehaviorSubject<workout_list[]>([]);
    readonly recent_sub_categories$: Observable<workout_list[]> = this.recent_sub_categories.asObservable();
    

    private favourite_sub_categories: BehaviorSubject<workout_list[]> = new BehaviorSubject<workout_list[]>([]);
    readonly favourite_sub_categories$: Observable<workout_list[]> = this.favourite_sub_categories.asObservable();
    
    private show_add_workout: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly show_add_workout$: Observable<boolean> = this.show_add_workout.asObservable();

    constructor(
        private api: ApiService,
        private toast: ToastController
    ){}

    toggle_add_menu(show: boolean) {
      this.show_add_workout.next(show);
    }

    public search_workouts(search: string){
        this.is_search_workouts_loading.next(true);
    
        let url = `workout?type=workout_sub_categories&search=${search}`;
        this.api
          .get(url)
          .pipe(take(1))
          .subscribe(
            (res: any) => {
              this.is_search_workouts_loading.next(false);
              this.workout_sub_categories.next(res.workout_sub_categories);
            },
            (err) => {}
          );
      }

      public verify_verifier(email, pin){
        this.is_loading.next(true);
    
        let url = `club?type=verify_verifier&email=${email}&pin=${pin}`;
        this.api
          .get(url)
          .pipe(take(1))
          .subscribe(
            (res: any) => {
              console.log("ver....", res);
              this.is_loading.next(false);
              this.verifier_verification.next(res.verification);
            },
            (err) => {}
          );
      }

      public ressetVerifierVerification() {
        this.verifier_verification.next(null);
      }


    public get_all_workouts(search: string) {
      this.is_search_workouts_loading.next(true);

      let url = `workout?type=all_workout_sub_categories&search=${search}`;
      this.api
        .get(url)
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            this.is_search_workouts_loading.next(false);
            this.all_workout_sub_categories.next(res.workout_sub_categories);
            this.favourite_sub_categories.next(res.favourite_sub_categories);
            this.recent_sub_categories.next(res.recent_sub_categories);
          },
          (err) => { }
        );
    }

    update_aci(id) {
      this.api
        .get(`reports?user_id=0&type=aci_overtime&period=3`)
        .pipe(take(1))
        .subscribe(
          (aci) => {
            this.api
              .get(`reports?aci=${aci.aciScore[2]}&user_id=${id}&type=user_aci&strength=${Number(aci.strength[2])}&agility=${Number(aci.agility[2])}&endurance=${Number(aci.endurance[2])}&explosiveness=${Number(aci.explosiveness[2])}&speed=${Number(aci.speed[2])}&date=${(new Date(moment(new Date()).add(1, 'day').toDate())).toDateString()}`)
              .pipe(take(1))
              .subscribe(
                (aci) => {
                  this.is_loading.next(false);
                  this.workout_created.next(true);
                  // window.location.href = window.location.href;
                },
                (err) => {
                  this.is_loading.next(false);
                }
              );
          },
          (err) => {
            this.is_loading.next(false);
          }
        );
    }

    post_workout(data, id = ''){
        this.is_loading.next(true);
        let url = `user_workout`;     
        this.api.post(url, data).pipe(take(1)).subscribe((res) => {
          
          // check aci is going to update;
          if (data.workouts.filter(work=> work.category_name === 'ACI workouts').length > 0) {
            this.update_aci(id);
          } else {
            // this.is_loading.next(false);
            // this.workout_created.next(true);
          }

          this.is_loading.next(false);
          this.workout_created.next(true);

          setTimeout(() => {
            window.location.href = window.location.origin + '/workouts';
          }, 1000);

        }, async (err) => {
            this.is_loading.next(false);
            let t = await this.toast.create({
                animated: true,
                color: 'warning',
                message: 'Please fill the quick workout entries data properly.',
                duration: 3000
              });
              t.present();
        })
        
    }

    clear_observables(){
        this.workout_sub_categories.next([]);
        this.workout_created.next(false);
    }

    clear_search_workout(){
        this.workout_sub_categories.next([]);
    }



}