import { Injectable } from "@angular/core";
import {v4 as uuidv4} from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class ActionSessionWorkouts {

    constructor(){}

    generate_workout_array(data: any[]): any[]{
        let workouts: any[] = [];
        
        data.forEach(d => {
            workouts.push({
                category_id: d.category_id,
                category_name: d.category_name,
                matrix: d.matrix,
                sub_category_id: d.sub_category_id,
                sub_category_name: d.sub_category_name,
                description: d.description,
                links: d.workout_links,
                value: [
                    this.generate_object_by_matrix(d.matrix)
                ]
            });
        });

        return workouts;
    }

    generate_single_workout(d) {
        return {
            category_id: d.category_id,
            category_name: d.category_name,
            matrix: d.matrix,
            sub_category_id: d.id,
            sub_category_name: d.name,
            description: d.description,
            unit_of_measurement: d.unit_of_measurement ? '( '+d?.unit_of_measurement?.split(',')[d?.unit_of_measurement?.split(',')?.length - 1]+' )' : '',
            value: [
                this.generate_object_by_matrix(d.matrix)
            ]
        }
    }

    generate_object_by_matrix(matrix: string): any{
        let w: any;
        switch(matrix){
            case "T":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    time: null
                }
                break;
            case "S,R,W":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    sets: null,
                    reps: null,
                    weight: null
                }
                break;
            case "T,D":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    time: '',
                    distance: null
                }
                break;  
            case "S,T":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    sets: null,
                    time: null,
                }
                break;  
            case "S,R,T":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    sets: null,
                    reps: null,
                    time: null
                }
                break;
            case "S,D,W":
            w = {
                _id: uuidv4(),
                matrix: matrix,
                sets: null,
                distance: null,
                weight: null
                }
                break; 
            case "SP":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    speed: null
                }
                break;
            case "D":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    distance: null
                }
                break;
            case "S,R":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    sets: null,
                    reps: null
                }
                break;
            case "P":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    percentage: null
                }
                break;
            case "H":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    height: null
                }
                break;
            case "PO":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    points: null
                }
                break;
            case "S,R,W,H":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    sets: null,
                    reps: null,
                    weight: null,
                    height: null
                }
                break;
            case "R,W":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    reps: null,
                    weight: null
                }
                break;
            case "W,D":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    weight: null,
                    distance: null
                }
                break;
            case "W":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    weight: null
                }
                break;
            case "S,R,W,D":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    sets: null,
                    reps: null,
                    weight: null,
                    distance: null
                }
                break;
            case "S,R,H":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    sets: null,
                    reps: null,
                    height: null
                }
                break;
            case "T,W":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    time: '',
                    weight: null
                }
                break;
            case "S":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    sets: null
                }
                break;
            case "R":
                w = {
                    _id: uuidv4(),
                    matrix: matrix,
                    reps: null
                }
                break;
        };

        return w;
    }
 }