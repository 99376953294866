import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clubFee'
})
export class ClubFeePipe implements PipeTransform {

  transform(value: string): string {
    let feeCharges = Number(value);
    let creditCardFee = ((3 / 100) * feeCharges) > 1 ? ((3 / 100) * feeCharges) : 1;
    let managementFee = this.managementFeeCalculation(feeCharges);
    let total = feeCharges + managementFee + creditCardFee;
    return Number(total).toFixed(2);
  }

  managementFeeCalculation(feeCharges) {

    let taxCalculate = (5 / 100) * feeCharges;
    if ((taxCalculate) <= 10 && ((taxCalculate) >= 5)) {
      return taxCalculate;
    } else if (taxCalculate < 5) {
      return  5;
    } else if (taxCalculate > 10) {
      return  10;
    }

    // return 10;

    // let taxCalculate = (5 / 100) * feeCharges;
    // if ((taxCalculate) <= 10 && ((taxCalculate) >= 8)) {
    //   return taxCalculate;
    // } else if (taxCalculate < 8) {
    //   return  8;
    // } else if (taxCalculate > 10) {
    //   return  10;
    // }
  }

}



