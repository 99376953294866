import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { game_results } from './game-result.model';

@Injectable({
  providedIn: 'root',
})
export class GameResultBll {
  //#region Loaders Observables

  private isLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoading$: Observable<boolean> =
    this.isLoading.asObservable();

  private game_results: BehaviorSubject<game_results> = new BehaviorSubject<game_results>(null);
  readonly game_results$: Observable<game_results> = this.game_results.asObservable();

  constructor(
    private api: ApiService,
    private toast: ToastService
  ) { }

  public get_game_results(user_id: string, page: number, limit: number) {
    this.isLoading.next(true);

    let url = `game_results?user_id=${user_id}&page=${page}&limit=${limit}`;
    this.api
      .get(url)
      .pipe(take(1))
      .subscribe(
        (res: game_results) => {
          this.game_results.next(res);
          this.isLoading.next(false);
        },
        (err) => { 
          this.isLoading.next(false);
        }
      );
  }

  public create_game_result(result) {
    this.isLoading.next(true);

    let url = `game_results`;

    this.api
      .post(url, result)
      .subscribe(async (response) => {
        this.isLoading.next(false);
        this.get_game_results(result.user_id, result.page, result.limit);
        this.toast.showToast(
          'Success',
          'Game result has been created successfully.'
        );
      },
      (err) => {
        this.isLoading.next(false);
      });
  }

  public update_game_result(result, id) {
    this.isLoading.next(true);

    let url = `game_results?id=${id}`;

    this.api
      .patch(url, result)
      .subscribe(async (response) => {
        this.isLoading.next(false);
        this.get_game_results(result.user_id, result.page, result.limit);
        this.toast.showToast(
          'Success',
          'Game result has been updated successfully.'
        );
      },
      (err) => {
        this.isLoading.next(false);
      });
  }

  public delete_game_result(id, page, limit, user_id, video_id = '') {
    this.isLoading.next(true);

    let url = `game_results?id=${id}`;

    if(video_id) {
      url += `&video_id=${video_id}`;
    }

    this.api
      .delete(url)
      .subscribe(async (response) => {
        this.isLoading.next(false);
        this.get_game_results(user_id, page, limit);
        this.toast.showToast(
          'Success',
          'Game result has been deleted successfully.'
        );
      },
      (err) => { 
        this.isLoading.next(false);
      });
  }

  public delete_game_video(video_id) {
    // this.isLoading.next(true);

    let url = `game_results?video_id=${video_id}&action=video`;

    this.api
      .delete(url)
      .subscribe(async (response) => {
        // this.isLoading.next(false);
        // this.toast.showToast(
        //   'Success',
        //   'Game result previous video has been deleted successfully.'
        // );
      },
      (err) => { 
        // this.isLoading.next(false);
      });
  }

}
