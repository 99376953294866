import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { NewActivityComponentBLL } from './components/events/new-activity/new-activity.bll';
import { AuthState } from './services/auth/auth.model';
import { AuthService } from './services/auth/auth.service';
import { pageView } from './services/menu/menu.pages';
import { MenuService } from './services/menu/menu.service';
import { PlatformService } from './services/platform/platform.service';
import { theme } from './services/theme/theme.model';
import { ThemeService } from './services/theme/theme.service';
import { NotificationsBLL } from './components/notifications/notifications.bll';
import { notificationCount } from './components/notifications/notifications.model';
import { take, skipWhile } from 'rxjs/operators';
import { decodeToken } from 'src/app/util/util';
import { AlertController } from '@ionic/angular';
import { HighlightBLL } from './views/profile/components/highlights/highlight.bll';
import { TeamingUpBLL } from './views/teaming-up/teaming-up.bll';
import { AppBLL } from './app.bll';
import { QuickWorkoutPage } from './views/quick-workout/quick-workout.page';
import { ImageSliderModelComponent } from './components/image-slider-model/image-slider-model.component';
import { WorkoutDetailBLL } from './components/workout-detail/workout-detail.bll';
import { QuickWorkoutsBLL } from './views/quick-workout/quick-workout.bll';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  user: AuthState;
  isMobileDevice: boolean;
  showHyperLink: boolean = false;
  showHyperLinkTraining: boolean = false;

  pages$: Observable<pageView[]>;
  auth$: Observable<AuthState>;
  show_support_bubble: boolean = false;

  //#region Data Observables
  themes$: Observable<theme[]>;
  notificationPayload$: Observable<any>;
  currentTheme$: Observable<string>;
  notificationsCount$: Observable<notificationCount>;
  show_workout_detail$: Observable<boolean>;
  show_add_new_quick_workout$: Observable<boolean>;
  // messageCount = 0;

  subscriptionsRes$: Observable<any>;
  openNotifications$: Observable<boolean>;

  //#endregion Data Observables

  notifications_sub: Subscription;

  //filter
  showNotificationMenu: boolean = false;
  openFAQ: boolean = false;
  displayGetPremium = false;

  fitnessDropdown = false;
  socialDropdown = false;
  selected_tab = '';
  selected_group = '';
  age_less$: Observable<boolean>;
  // panda-connect-chat



  userDecodedToken;
  constructor(
    private platform: PlatformService,
    private router: Router,
    private modal: ModalController,
    private menu: MenuService,
    private auth: AuthService,
    private popover: PopoverController,
    private theme: ThemeService,
    private add_menu_bll: NewActivityComponentBLL,
    private highlight_bll: HighlightBLL,
    private notifications_bll: NotificationsBLL,
    private alertController?: AlertController,
    // private calendar?: Calendar,
    // private toast?: ToastService,
    // private membershipBll?: MembershipBLL,
    private teamingUpBll?: TeamingUpBLL,
    private appBll?: AppBLL,
    private workoutDetailBLL?: WorkoutDetailBLL,
    private quickWorkoutBll?: QuickWorkoutsBLL,
    private activatedRoute?: ActivatedRoute
    // private chatBLL?: ChatBll
  ) {
    this.isMobileDevice = this.platform.isMobile();
    this.pages$ = this.menu.pages$;
    this.age_less$ = this.menu.age_less$;
    this.auth$ = this.auth.auth$;
    this.currentTheme$ = this.theme.currentTheme$;
    this.themes$ = this.theme.themes$;
    this.notificationPayload$ = this.auth.notificationPayload$;
    this.checkDevicePlatform();
    this.openNotifications$ = this.auth.openNotifications$;

    // this.calendar.createCalendar('PANDAEvent').then(
    //   (msg) => { console.log(msg);
    //   console.log("calendar createds") },
    //   (err) => { console.log("err,", err); }
    // );
    // // let calOptions = this.calendar.getCalendarOptions();
    // // calOptions.calendarName = 'PANDAEvents';
    // this.calendar.createEventWithOptions('name of event', '', '',  new Date('2023-08-28T09:00:00-07:00'), new Date('2023-08-28T09:00:00-07:00'), {calendarName: 'PANDAEvent'});
  }

  ngOnInit() {
    this.auth.listen();
    // this.activatedRoute?.params.subscribe((params) => {

    //   console.log("params", params)
    // });

    // Not getting token on first reload!
    this.auth$
      .pipe(
        skipWhile((val) => !val.token),
        take(1)
      )
      .subscribe(async (user) => {
        // let age = JSON.parse(localStorage.getItem('age'));
        // if (age && age <= 12) {
        //   this.age_less = true;
        //   console.log("here is under 12 res", age)
        // }

        // this.activatedRoute?.params.pipe(skipWhile(id => !id), take(1)).subscribe((params) => {

        //   console.log("params", params)
        // });


        this.user = user;
        this.userDecodedToken = await decodeToken(user.token);
        this.subscriptionsRes$ = this.appBll.subscriptions$;
        this.appBll.get_subscriptions();
        // this.openAciDetailsModel();

        // this.membershipBll.get_subscriptions();
        this.initObservables();
        this.initDataRetrieval();


        // this.chatBLL.channels$.subscribe((res: any) => {
        //   if (res && res.channels) {

        //     let count = 0;

        //     res.channels.forEach(ch => {
        //       console.log("ch", ch);
        //       count = +ch.counter + count
        //     });

        //     this.messageCount = count;
            

          
        //   }
        // });



      });

    // refresh notification count while listening to FCM.
    this.notifications_sub = this.notificationPayload$.subscribe((res) => {
      this.notifications_bll.get_notification_count();
    });

    this.openNotifications$?.subscribe(res => {
      this.openNotificationMenuAndroid();
    })

    this.show_workout_detail$ = this.workoutDetailBLL.show_workout_detail$;
    this.show_add_new_quick_workout$ = this.quickWorkoutBll.show_add_workout$;

    this.menu.initPages(this.user?.role);
  }

  initObservables() {
    this.notificationsCount$ = this.notifications_bll.notificationsCount$;
  }

  toggleFitness(type) {
    // if (type && type == 'fitness') {
    //   this.fitnessDropdown = !this.fitnessDropdown;
    // } else if (type && type == 'social') {
    //   this.socialDropdown = !this.socialDropdown;
    // }

    if(type == this.selected_group) {
      this.selected_group = '';
    } else {
      this.selected_group = type;
    }

  }

  goToGroups(data) {
    console.log(window.location.pathname.split('/')[2] , window.location.pathname)
    if (window.location.pathname.split('/')[2] === 'group') {
      this.router.navigate(['/teaming-up'])
      setTimeout(() => {
        this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
          queryParams: { admin_id: data.admin_id, group: data.group, current_user_role: data?.role, tab: 'forum' },
        });
      }, 0);
    }else{
      this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
        queryParams: { admin_id: data.admin_id, group: data.group, current_user_role: data?.role, tab: 'forum' },
      });
    }
    this.flushPayloadOnClose();
   
  }

  goToGroupsParent(data) {
    if (window.location.pathname.split('/')[2] === 'group') {
      this.router.navigate(['/teaming-up'])
      setTimeout(() => {
        this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
          queryParams: { admin_id: data.admin_id, group: data.group, current_user_role: data?.role, tab: 'parent_forum' },
        });
      }, 0);
    }
    else{
      this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
        queryParams: { admin_id: data.admin_id, group: data.group, current_user_role: data?.role, tab: 'parent_forum' },
      });
    }
    this.flushPayloadOnClose();
   
  }

  goToGroupChat(data) {
    if (window.location.pathname.split('/')[2] === 'group') {
      this.router.navigate(['/teaming-up'])
      setTimeout(() => {
        this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
          queryParams: { admin_id: data.admin_id, group: data.teaming_up_group_name, current_user_role: data?.role, tab: 'group_chat', channel_id: data.teaming_up_channel_id },
        });
      }, 0);
    }else{
      this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
        queryParams: { admin_id: data.admin_id, group: data.teaming_up_group_name, current_user_role: data?.role, tab: 'group_chat', channel_id: data.teaming_up_channel_id },
      });
    }
    this.flushPayloadOnClose();
  }

  goToGroupNotices(data) {
    this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
      queryParams: { admin_id: data.admin_id, group: data.group, current_user_role: data.role, tab: 'notice' },
    });
    this.flushPayloadOnClose();
  }

  goToGroupCalendar(data) {
    this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
      queryParams: { admin_id: data.admin_id, group: data.group, current_user_role: data?.role, tab: 'calendar' },
    });
    this.flushPayloadOnClose();
  }

  async initDataRetrieval() {
    this.auth.auth$.pipe(take(1), skipWhile(val => !val.id)).subscribe(u => {
      this.auth.verify_under_age();
    });
    this.notifications_bll.get_notification_count();
    if (!this.userDecodedToken.dob) this.menu.disableRoutes('all');
    // if (!this.userDecodedToken.age) this.menu.disableRoutes('age_restrictions');
    if (this.userDecodedToken.parent_added) this.menu.disableRoutes('unlock');
    this.subscriptionsRes$.subscribe((subs: any) => {
      if (subs?.subscriptions?.filter(sub => sub?.status === 'active').length === 0) {
        this.menu.disableRoutes('substriction_check');
        this.displayGetPremium = true;
      } 
    });
  }

  redirectToUserWorkouts(id) {
    this.flushPayloadOnClose();
    this.router.navigate([`/profile/${id}`], {
      queryParams: { workout: true },
    });
  }

  redirectToChallenge(workout_category_id, challenge_id) {
    // this.router.navigate(['/challenge/categories/'+workout_category_id+'/details/'+challenge_id]);
    // this.router.navigate(['/challenge/categories/'+workout_category_id]);
    this.router.navigate(['/challenge']);
    this.flushPayloadOnClose();
  }

  showAddButton(): boolean {
    let show = false;

    if (this.userDecodedToken?.dob === true && this.userDecodedToken?.weight === true && this.userDecodedToken?.gender === true) {
      if (this.userDecodedToken?.age === true)
        show = true;
      else {
        if (this.userDecodedToken?.parent_added === true)
          show = true;
      }
    }

    return show;
  }

  async ionViewDidEnter() {
    this.initDataRetrieval();
    // this.openAciDetailsModel();
  }

  ngOnDestroy() {
    this.notifications_sub.unsubscribe();
  }

  whiteListed = ['auth/reset-password', 'auth/register?parent_email', 'auth/register?ref', 'policies'];
  whiteListedFlag = this.whiteListed.some((i) =>
    window.location.href.includes(i)
  );

  async checkDevicePlatform() {
    if (!this.whiteListedFlag) {
      if (this.isMobileDevice) {
        this.router.navigate(['auth/welcome']);
      } else {
        this.router.navigate(['auth/login']);
      }
    }
  }

  navigateToPage(url: string, tab: string) {
    this.selected_tab = tab;
   
    if((this.displayGetPremium && tab == 'comparison') || (this.displayGetPremium && tab == 'email-coach') ) {
      this.router.navigate(['/challenge']);
    } else {
      this.menu.changePage(url);
    }

  }

  openHyperLink(link: string, id: string) {
    if (this.isMobileDevice) {
      this.HyperLinkDialog(link, id);
    } else {
      window.open(link, '_blank');
    }
  }

  openAccountSettings() {
    this.popover.dismiss();
    this.router.navigate(['settings']);
  }
  
  openSubscriptions() {
    this.popover.dismiss();
    this.router.navigate(['settings'],
    {
      queryParams: {membership: 'true' },
    });
  }

  async setTheme(color: string) {
    this.theme.setTheme(color);
  }

  async logOut() {
    await this.auth.logout();
    this.popover.dismiss();
    this.menu.hideMenu();
    this.router.navigate(['auth/login']);
    // window.location.href = window.location.href;
    window.location.reload();

  }

  flushPayloadOnClose() {
    this.auth.notificationPayload$.next(null);
  }

  notificationsPopoverDismiss() {
    this.notifications_bll.get_notification_count();
  }

  show_addMenu() {
    this.menu.collapseMenu();
    this.add_menu_bll.toggle_add_menu(true);
  }

  async show_quick_workout() {
    this.selected_tab = 'show_quick_workout';
    this.menu.collapseMenu();
    this.quickWorkoutBll.toggle_add_menu(true);
    // let m = await this.modal.create({
    //   component: QuickWorkoutPage,
    //   animated: true,
    //   backdropDismiss: false,
    //   cssClass: 'fullscreen_modal'
    // });
    // await m.present();
  }

  collapseMenu() {
    this.menu.collapseMenu();
  }

  openAddHighlightModel() {
    this.menu.collapseMenu();
    this.highlight_bll.openAddHighlightModel();
  }
  
  redirectToHighlight() {
    this.menu.collapseMenu();
    this.router.navigate(['/profile/0'], {
      queryParams: { videos: true },
    });
  }

  openAddTeamingUpGroup() {
    this.menu.collapseMenu();
    this.teamingUpBll.openAddGroupModel(this.user?.role);
  }

  // calls when auth initilized etc login with username and password
  // async openAciDetailsModel() {
  //   // this.activatedRoute?.params.pipe(skipWhile(id => !id), take(1)).subscribe((params) => {
  //   //   console.log("params1221", params);
  //   //   if(!params.stopQuickMenue) {
  //   //     this.auth.get_user_aci(this.user?.role);
  //   //   }
  //   // });
  //   console.log("params1221 out");

  //   this.activatedRoute?.queryParams.pipe(skipWhile(p => !p), take(1))
  //   .subscribe((params) => {
  //     if(!params.stopQuickMenue) {
  //             console.log("params1221 in", params);

  //       this.auth.get_user_aci(this.user?.role);
  //     }
  //   });
  // }

  openNotificationMenu() {
    if (this.showNotificationMenu) {
      this.auth.closeNotifications();
    }
    this.menu.collapseMenu();
    this.showNotificationMenu = !this.showNotificationMenu;
    this.notificationsPopoverDismiss();
    this.flushPayloadOnClose();
  }

  openNotificationMenuAndroid() {
    this.menu.collapseMenu();
    this.showNotificationMenu = true;
    this.notificationsPopoverDismiss();
    this.flushPayloadOnClose();
  }

  goToProfile(id) {
    this.menu.collapseMenu();
    this.router.navigate(['/profile/' + id], {
      queryParams: {
        replaceUrl: true,
        skipLocationChange: false
      }
    });
  }

  goToHome() {
    this.selected_tab = 'home';
    this.menu.collapseMenu();
    this.router.navigate(['/home'], {
      queryParams: {
        stopQuickMenue: true,
        replaceUrl: true,
        skipLocationChange: false
      }
    });
  }

  goToDailyActivity() {
    this.selected_tab = 'home';
    this.menu.collapseMenu();
    this.router.navigate(['/panda/activities'], {
      queryParams: {
        stopQuickMenue: true,
        replaceUrl: true,
        skipLocationChange: false
      }
    });
  }

  showHyperLinkFunc(flag, id) {
    if (id === 'training') {
      this.showHyperLinkTraining = flag;
    } else if (id === 'nutrition') {
      this.showHyperLink = flag;
    }
  }

  refresh() {
    window.location.reload();
  }

  closeSlideOver(event) {
    if (event == false) {
      this.showNotificationMenu = false;
    } else if (event == null) {
      this.showNotificationMenu = false;
    } else {
      this.showNotificationMenu = true;
    }
  }

  public async HyperLinkDialog(link, id) {

    if (id === 'training') {
      
      const alert = await this.alertController.create({
        subHeader: `Use the ‘Panda15’ coupon code at checkout to receive a 15% discount on items in your cart.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => { },
          },
          {
            text: 'Go',
            role: 'go',
            handler: () => {
              window.open(link, '_blank');
            },
          },
        ],
      });

      await alert.present();

    } else if (id === 'nutrition') {

      const alert = await this.alertController.create({
        subHeader: `Use 'Panda20' as your discount code to receive 20% at checkout.  If you subscribe to any order, you will receive 35% at checkout`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => { },
          },
          {
            text: 'Go',
            role: 'go',
            handler: () => {
              window.open(link, '_blank');
            },
          },
        ],
      });

      await alert.present();

    } else {
      window.open(link, '_blank');
    }

  }

  async openQuickStartModel() {
    // check that the logged in user is athlete and navigate from Login Page
      const m = await this.modal.create({
        component: ImageSliderModelComponent,
        animated: true,
        backdropDismiss: true,
        cssClass: 'sliderModel'
      });
      m.onDidDismiss().then(data => {
      });
      await m.present();
  }

  open_youtube_channel() {
    window.open('https://www.youtube.com/@pandastronger7618/featured', '_blank');
  }

  open_web_donations() {
    window.open('https://www.pandastronger.com/donations', '_blank');
  }

  open_youtube_videos(type) {
    if (type === 'quick-start') {
      window.open('https://www.youtube.com/watch?v=ZJt4mwjzj2o&list=PLKEGN3VAbZgfPsvNZNGfi3_Kn1Fc_X0mY&index=1&t=32s', '_blank');
    } else if (type === 'overview') {
      window.open('https://www.youtube.com/watch?v=ZJW6C27mdMk&list=PLKEGN3VAbZgfPsvNZNGfi3_Kn1Fc_X0mY&index=2&t=26s', '_blank');
    } else if (type === 'features') {
      window.open('https://www.youtube.com/watch?v=sxaqwO2YI2o', '_blank');
    } else if (type === 'groups') {
      window.open('https://www.youtube.com/watch?v=H2ZJDVNBmp8', '_blank');
    } else if (type === 'aci') {
      window.open('https://youtu.be/Dcn-vXI4HAg', '_blank');
    } else if (type === 'entry') {
      window.open('https://youtu.be/ZH-6aDucHqQ', '_blank');
    }  else if (type === 'aci-compute') {
      window.open('https://pandastrongerblobstorage.blob.core.windows.net/documents/documents/teaming_up_doc_b10678c2-bd3b-4e5e-b3bc-ec43e65ee35e', '_blank');
    } 
  }

  openDocuments(type) {
    if (type === 'athlete') {
      window.open('https://netorgft6776948-my.sharepoint.com/:b:/g/personal/glen_parrillo_parcsports_com/EWcQnjW58PVFn5sB5wamDVIBFWn5-WDGB_ZbKutj2ocyYQ?e=lp0MVY&isSPOFile=1', '_blank');
    } else if (type === 'coach') {
      window.open('https://netorgft6776948-my.sharepoint.com/:b:/g/personal/glen_parrillo_parcsports_com/EeUcdRf_EUJMsoUAnplvI-oBEf6YPIXxAJEUSHmstQseFw?e=c8KHDn', '_blank');
    } else if (type === 'parent') {
      window.open('https://netorgft6776948-my.sharepoint.com/:b:/g/personal/glen_parrillo_parcsports_com/EYCfLvxRIDtBpS4KNfkvmSkBl6ORNP-zd50BcXhmEBy6Hg?e=TjFmng', '_blank');
    }
  }

  public async FAQDialog() {
    this.openFAQ = true;
  }


}
