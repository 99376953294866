import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { VideoUploadBLL } from './video-upload.bll';
import { Observable, throwError } from 'rxjs';
import { video } from './video.model';
import axios from 'axios';
import { AppBLL } from 'src/app/app.bll';

@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss'],
})
export class VideoUploadComponent implements OnInit, OnDestroy {

  @Input() module: string;
  @Input() user_id: string;
  @Input() preTitle?: string;
  @Input() preDescription?: string;
  @Input() fromPosts?: boolean = false;
  form: FormGroup;
  postOnSocial: boolean = true;

  isLoading: boolean = false;
  isPreProcessLoading: boolean = false;
  file: File;
  _video_url: string;
  preprocessingVideo: any;
  postprocessingVideo: boolean = false;
  uploadProgress: number;
  intervPolling: NodeJS.Timeout;

  //Video Type Configuration
  videoType: string = 'highlight';

  //#region Observables
  //Loaders
  //Variables
  videoCreated$: Observable<number>;
  //#endregion Observables
  
  constructor(
    private modal: ModalController,
    private fb: FormBuilder,
    private bll: VideoUploadBLL,
    private AppBLL: AppBLL
  ) { 
    this.mapObservables();
  }

  ngOnDestroy(): void {
    this.bll.resetObservables();
    clearInterval(this.intervPolling);
  }

  ngOnInit() {
    this.form = this.fb.group(this.initializeForm());
    this.generateUploadVideoURL();
  }

  mapObservables(){
    //Loaders
    //Variables
    this.videoCreated$ = this.bll.videoCreated$;
  }

  async generateUploadVideoURL(){
    this.isPreProcessLoading = true;

    this.bll.generateUploadVideoURL(this.module, this.user_id).then(res => {
      this.preprocessingVideo = res;
    }).finally(() => {
      this.isPreProcessLoading = false
    })
  }

  async videoFileChanged(ev){
    const reader = new FileReader();
    if (ev.target.files && ev.target.files.length) {
      const [file] = ev.target.files;
      this.file = file;
    }
  }

  async createAndUpload(){
    if(this.file){
      let video: video = {
        title: this.title.value,
        description: this.description.value,
        video_id: this.preprocessingVideo.video_id,
        module: this.module,
        user_id: this.user_id,
        video_type: this.videoType,
        status: 'init'
      }
      this.isLoading = true;
      this.bll.createVideoInDB(video, this.postOnSocial).then(async(res) => {
        const arrayBuffer = await this.readFileAsArrayBuffer(this.file);
        await axios.put(this.preprocessingVideo.mux_video.url, arrayBuffer, {
          headers: {
            'Content-Type': this.file.type,
          },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        }).then(async (uploadRes) => {
          await this.pollForPostProcessing(video);
        }).catch((uploadErr) => {
          throwError(uploadErr);
        });
      }).catch((err)=> {
        this.isLoading = false;
        console.log(err);
      }).finally(() => {
      });
    }
  }


  initializeForm() {
    return {
      title: [this.preTitle, [Validators.required]],
      description: [this.preDescription]
    }
  }

  private async pollForPostProcessing(video: video){
    this.postprocessingVideo = true;
    this.intervPolling = setInterval(async () => {
      await this.bll.getPostProcessingStatus(video).then(async (res) => {
        if(res && res.length > 0 && res[0]){
          if(res[0].status === 'ready'){
            this.isLoading = false;
            clearInterval(this.intervPolling);
            this.AppBLL.get_subscriptions();
            this.modal.dismiss({
              action: 'video-created',
              video_type: this.videoType,
              video_id: video.video_id
            });
          }
        }
      });
    }, 5000);
  }

  private readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = this.getFileReader();

      reader.onload = () => {
        const fileData = reader.result as ArrayBuffer;
        resolve(fileData);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  private getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
}


  closeModal(){
    this.modal.dismiss();
  }

  //#region FORM GETTERS

  public get title(){
    return this.form.get('title');
  }

  public get description(){
    return this.form.get('description');
  }

  //#endregion FORM GETTERS

}
