import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { video } from 'src/app/components/video-upload/video.model';
import { ViewHighlightVideoBLL } from './view-highlight-video.bll';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-view-highlight-video',
  templateUrl: './view-highlight-video.component.html',
  styleUrls: ['./view-highlight-video.component.scss'],
})
export class ViewHighlightVideoComponent implements OnInit {

  @Input() video: video;
  @Input() user_id?: string;
  @Input() user_posts?: boolean;
  collapseCaption: boolean = true;

  constructor(
    private modal: ModalController,
    private alert: AlertController,
    private loader: LoadingController,
    private bll: ViewHighlightVideoBLL,
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.initMuxPlayer();
  }

  initMuxPlayer(){
    const muxPlayer = document.querySelector("mux-player") as HTMLElement;
    if(this.video.width && this.video.height){
      muxPlayer.style.aspectRatio = `${this.video.width} / ${this.video.height}`;
      if(this.video.width > this.video.height){
        muxPlayer.style.width = `100%`;
      } else {
        muxPlayer.style.height = `100%`;
      }
    }
    muxPlayer.setAttribute('playback-id', this.video.playback_id);
  }

  closeModal(isDeleted?: boolean){
    if(isDeleted){
      this.modal.dismiss({
        action: 'video-deleted'
      });
    } else {
      this.modal.dismiss();
    }
  }

  async openDeleteVideoConfirmation(){
    const a = await this.alert.create({
      header: 'Delete Video',
      subHeader: 'Are you sure?',
      message: 'This will delete the video permanently from PandaStronger storage and will not be able to recover back.',
      animated: true,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Yes, delete',
          role: 'confirm',
          handler: () => {
            this.deleteVideo();
          }
        }
      ]
    });

    await a.present();
  }

  async deleteVideo(){
    const l = await this.loader.create({
      animated: true,
      message: 'Deleting video...',
      spinner: 'crescent',
      backdropDismiss: false,
    });

    await l.present();

    if(this.video.module === 'workouts'){
      this.bll.deleteVideoByPlaybackID(this.video.playback_id).then(async(res) => {
        this.closeModal(true);
      }).catch(async(err) => {
        this.toast.showToast('', 'There was an unexpected issue deleting the video. Please try again.', 'danger');
      }).finally(() => {
        l.dismiss();
      });
    } else {
      this.bll.deleteVideo(this.video).then(async(res) => {
        this.closeModal(true);
      }).catch(async(err) => {
        this.toast.showToast('', 'There was an unexpected issue deleting the video. Please try again.', 'danger');
      }).finally(() => {
        l.dismiss();
      });
    }
  }

}
