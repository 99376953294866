export async function convertBase64ToFile(file_name, url) {
  let file;
  await fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      file = new File([blob], file_name + new Date().getTime(), {
        type: "image/png",
      });
    });
  return file;
}

export async function decodeToken (token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}