import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationsBLL } from './notifications.bll';
import { Notifications } from './notifications.model';
import { take, skipWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
// AUTH
import { AuthService } from 'src/app/services/auth/auth.service';
import { AuthState } from 'src/app/services/auth/auth.model';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  user_id: string;

  selected_request_id: string;
  @Output() closeSlideOver = new EventEmitter<boolean>();
  user: AuthState;

  followBack: Observable<any[]>;
  followBackLoading$: Observable<boolean>;

  senderIds = [];

  constructor(
    private bll: NotificationsBLL,
    private auth: AuthService,
    private router: Router
  ) {
    this.bll.followBack$.subscribe(res => {
      this.senderIds = res;
    });
  }

  //#region Loaders

  isLoading_requests$: Observable<boolean>;
  isLoading_notifications$: Observable<boolean>;
  isLoading_acceptRequest$: Observable<boolean>;
  isLoading_rejectRequest$: Observable<boolean>;

  //#endregion Loaders

  //#region Data Observables
  auth$: Observable<AuthState>;
  // requests$: Observable<requestsList>;
  notifications$: Observable<Notifications>;
  //#endregion Data Observables

  ngOnInit() {
    this.followBack = this.bll.followBack$;
    this.followBackLoading$ = this.bll.isLoadingFollowback$
    this.auth$ = this.auth.auth$;

    this.auth$
      .pipe(
        skipWhile((val) => !val.token),
        take(1)
      )
      .subscribe((user) => {
        this.user_id = user.id;
        this.user = user;
        this.initObservables();
        this.initDataRetrieval();
      });
  }

  ngOnDestroy() {}

  async ionViewDidEnter() {
    this.initDataRetrieval();
  }

  initObservables() {
    this.isLoading_requests$ = this.bll.isLoading_requests$;
    this.isLoading_notifications$ = this.bll.isLoading_notifications$;
    this.isLoading_acceptRequest$ = this.bll.isLoading_acceptRequest$;
    this.isLoading_rejectRequest$ = this.bll.isLoading_rejectRequest$;
    // this.requests$ = this.bll.requests$;
    this.notifications$ = this.bll.notifications$;
  }

  initDataRetrieval() {
    // this.bll.get_requests(this.user_id);
    this.bll.get_notifications();
  }

  acceptRejectRequest(sender_id, receiver_id, status, push_notification_id, followRequest = false) {
    this.selected_request_id = sender_id;

    if (status === 'accepted') {
      this.isLoading_acceptRequest$ = this.bll.isLoading_acceptRequest$;
    } else {
      this.isLoading_rejectRequest$ = this.bll.isLoading_rejectRequest$;
    }

    this.bll.accept_reject_request(
      sender_id,
      receiver_id,
      status,
      push_notification_id ?? '',
      followRequest,
      this.user?.name
    );
  }

  requestAction(teaming_up_id: string, member_id: string, status: string, push_notification_id: string, title: string) {
    this.selected_request_id = teaming_up_id;
    this.bll.requestAction(teaming_up_id, member_id, status, push_notification_id, title);
  }

  goToSettings() {
    this.closeSlideOver.emit(false);
    this.router.navigate(['settings'], {
      queryParams: { verification: true },
    });
  }

  goToProfile() {
    this.closeSlideOver.emit(false);
    this.router.navigate(['/profile/0'], {
      queryParams: { recommendation: true },
    });
  }

  isFollowBack(id: any) {
    return this.senderIds?.includes(id);
  }

  isUnderAge(birthdate: Date): boolean {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age <= 12 ? true : false;
  }

  followBackRequest(notification) {
    this.bll.send_request(
      this.user.id,
      notification.id,
      "sent",
      this.user.name,
      this.user.display_picture,
      notification.push_notification_id
    )
  }

  navigateToGroup(notification) {
    this.closeSlideOver.emit(false);
    this.router.navigate([`teaming-up/group/${notification?.teaming_up_id}`], {
      queryParams: { admin_id: notification?.admin_id, group: notification?.title, current_user_role:'admin', tab: 'requests'},
    });
  }

}
