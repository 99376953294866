import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NewQuickWorkoutPageRoutingModule } from './new-quick-workout-routing.module';

import { NewQuickWorkoutPage } from './new-quick-workout.page';
import { InputMaskModule } from '@ngneat/input-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NewQuickWorkoutPageRoutingModule,
    InputMaskModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NewQuickWorkoutPage],
  exports: [NewQuickWorkoutPage]
})
export class NewQuickWorkoutPageModule {}
