import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { pageView, pages } from './menu.pages';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private pages: BehaviorSubject<pageView[]> = new BehaviorSubject<pageView[]>(
    []
  );

  private age_less: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  // private no_subscription: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
  //   false
  // );


  constructor(private menu: MenuController, private router: Router) {
    this.initPages();
  }

  public showMenu() {
    this.menu.enable(true);
  }

  public hideMenu() {
    this.menu.enable(false);
  }

  public collapseMenu() {
    if (this.menu.isOpen()) {
      this.menu.close();
    }
  }

  public initPages(role?) {
    let p = pages;

    // Remove Pages depending on role.
    if (role == 'athlete') {
      p = this.removePageObjectWithId(p, 'post-event');
    }

    this.pages.next(p);
    this.checkCurrentRoute();
  }

  removePageObjectWithId(arr, id) {
    return arr.filter((obj) => obj.id !== id);
  }

  public changePage(url: string) {
    this.collapseMenu();
    if(url !== '/myVideos'){
      this.router.navigate([url], {
        replaceUrl: true,
        skipLocationChange: false,
      });
    } else {
      this.router.navigate(['/profile/0'], {
        replaceUrl: true,
        skipLocationChange: false,
        queryParams: {
          videos: true
        }
      })
    }
  }

  public checkCurrentRoute() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        let pages: pageView[] = [];
        let selected_page_index = null;
        let to_page_index = null;

        this.pages
          .pipe(take(1))
          .subscribe((_pages) => {
            pages = _pages;
          })
          .unsubscribe();

        pages.forEach((p, pi) => {
          if (p.selected) {
            selected_page_index = pi;
          }
          if (p.url === event['url']) {
            to_page_index = pi;
          }
        });

        if (selected_page_index !== null && selected_page_index !== undefined) {
          pages[selected_page_index].selected = false;
        }

        if (to_page_index !== null && to_page_index !== undefined) {
          if (pages[to_page_index].url === '/challenge' && pages[to_page_index].id != 'challenge') {
            pages[8].selected = true;
          } else {
            pages[to_page_index].selected = true;
          }
        }

        this.pages.next(pages);
      });
  }

  public disableRoutes(type: string) {
    let pages: pageView[] = [];

    this.pages
      .pipe(take(1))
      .subscribe((_pages) => {
        pages = _pages;
      })
      .unsubscribe();

    if (type == 'all') {
      pages.forEach((p, pi) => { 
        if(p.title !== 'Home')
          pages[pi].disable = true;
      });
    } else if (type == 'age_restrictions') {
      this.age_less.next(true);
      // pages.forEach((p, pi) => {
      //   if (
      //     // 'pointer-events-none text-gray-400 bg-background-nav-selected':age_less, 
      //     p.id == 'panda-connect-chat' ||
      //     p.id == 'panda-connect' ||
      //     p.id == 'email-coach' ||
      //     p.id == 'comparison' ||
      //     p.id == 'search-events' ||
      //     p.id == 'home' ||
      //     p.id == 'post-event'
      //   ) {
      //     pages[pi].disable = true;
      //   } else {
      //     pages[pi].disable = false;
      //   }
      // });
    } else if (type == 'unlock') {
      pages.forEach((p, pi) => {
        if (p.id == 'search-events' || p.id == 'post-event') {
          pages[pi].disable = true;
        } else {
          pages[pi].disable = false;
        }
      });
    } else if (type == 'substriction_check') {
      pages.forEach((p, pi) => {
        if (
          // p.id == 'panda-connect-chat' ||
          p.id == 'email-coach' ||
          p.id == 'comparison'
        ) {
          // pages[pi].disable = true;
          pages[pi].url = '/challenge';
        }
      });
    }
    this.pages.next(pages);
  }

  public get pages$(): Observable<pageView[]> {
    return this.pages.asObservable();
  }

  public get age_less$(): Observable<boolean> {
    return this.age_less.asObservable();
  }

  // public get no_subscription$(): Observable<boolean> {
  //   return this.no_subscription.asObservable();
  // }
}
