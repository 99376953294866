import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Root } from './teaming-up.model';
import { take } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { StorageService } from 'src/app/services/azure/storage/storage.service';
import { Router } from '@angular/router';
import { Subscription } from '../settings/components/monetization/components/membership/membership.model';
import { AppBLL } from 'src/app/app.bll';
@Injectable({
  providedIn: 'root',
})
export class TeamingUpBLL {
  //#region Loaders Observables

  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();

  private createGroupLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly createGroupLoading$: Observable<boolean> =
    this.createGroupLoading.asObservable();

    private createGroupId: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  readonly createGroupId$: Observable<string> =
    this.createGroupId.asObservable();

  private updateGroupLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly updateGroupLoading$: Observable<boolean> =
    this.updateGroupLoading.asObservable();

  private isAddGroupModelOpen: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isAddGroupModelOpen$: Observable<boolean> =
    this.isAddGroupModelOpen.asObservable();

  private isUpdateGroupModelOpen: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isUpdateGroupModelOpen$: Observable<boolean> =
    this.isUpdateGroupModelOpen.asObservable();

  //#endregion Loaders Observables

  //#region Data Observables

  private groups: BehaviorSubject<Root> = new BehaviorSubject<Root>(null);
  readonly groups$: Observable<Root> = this.groups.asObservable();

  // private selectedGroup: BehaviorSubject<Root> = new BehaviorSubject<Root>(null);
  // readonly selectedGroup$: Observable<Root> = this.selectedGroup.asObservable();
  selectedGroup$: Subject<any> = new Subject<any>();

  private creation_status: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);
  readonly creation_status$: Observable<string> =
    this.creation_status.asObservable();

  //#endregion Data Observables

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private storage: StorageService,
    // private memberBLL: MembershipBLL,
    private appBll: AppBLL,
    private router: Router
  ) { }

  public getTeamingUpGroups(search: string, page: number, limit: number, onlyAdmin?: boolean) {
    this.isLoading.next(true);

    let url = `teamingUp?type=get_groups&search=${search}&page=${page}&limit=${limit}`;
    if (onlyAdmin) {
      url += `&admin_only=${onlyAdmin}`
    }

    this.api
      .get(url)
      .pipe(take(1))
      .subscribe(
        (response: Root) => {
          this.groups.next(response);
          this.isLoading.next(false);
        },
        (err) => {
          this.isLoading.next(false);
        }
      );
  }

  public async createTeamingUpGroup(title: string, description: string,
    admin_id: string, created_by: string, file: Blob) {
    this.createGroupLoading.next(true);
    let display_picture = '';
    // needed to rework we don't have teaming up ID.
    let uuid = new Date().getTime();
    if (file) {
      display_picture = await this.storage.uploadFile(
        'teaming_up_group_photo_',
        file,
        uuid.toString()
      );
    }
    let url = `teamingUp?type=create_group`;
    this.api
      .post(url, {
        title,
        description,
        admin_id,
        created_by,
        display_picture,
      })
      .pipe(take(1))
      .subscribe(
        (response: any) => {
          this.createGroupId.next(response.id);
          this.createGroupLoading.next(false);
          this.creation_status.next('success');
          this.toast.showToast('Success!', 'Group has been created.');
          this.toggleAddGroupModel(false);
          this.appBll.get_subscriptions();
        },
        (err) => {
          this.createGroupLoading.next(false);
          this.toast.showToast('Error!', 'Something went wrong!', 'danger');
        }
      );
  }

  ressetCreatedGroup() {
    this.createGroupId.next("");
  }

  public async deleteTeamingUpGroup(id, search, page, limit) {
    let url = `teamingUp?type=delete_group&id=${id}`;
    this.api.delete(url).subscribe(
      async () => {
        this.getTeamingUpGroups(search, page, limit);
        this.appBll.get_subscriptions();
        this.toast.showToast('Success!', 'Group has been deleted.');
      },
      async (err) => {
        console.log('HTTP Error', err);
        this.toast.showToast('Error!', 'Something went wrong!', 'danger');
      }
    );
  }

  public async editTeamingUpGroup(id, title, description, file) {
    let url = `teamingUp?type=update_group&id=${id}`;
    this.updateGroupLoading.next(true);
    let uuid = new Date().getTime();
    let display_picture = '';
    if (typeof file == 'object') {
      display_picture = await this.storage.uploadFile(
        'teaming_up_group_photo_',
        file,
        uuid.toString()
      );
    } else {
      display_picture = file;
    }
    this.api
      .patch(url, {
        title,
        description,
        display_picture,
      })
      .pipe(take(1))
      .subscribe(
        (response: any) => {
          this.updateGroupLoading.next(false);
          this.toast.showToast('Success!', 'Group has been updated.');
          this.creation_status.next('success');
        },
        (err) => {
          this.updateGroupLoading.next(false);
          this.toast.showToast('Error!', 'Something went wrong!', 'danger');
        }
      );
  }

  public requestToJoinGroup(
    teaming_up_id,
    member_id,
    member_role,
    status,
    sender_id,
    admin_push_notification_id,
    sender_name,
    sender_display_picture,
    admin_id,
    search,
    page,
    limit
  ) {
    let url = `teamingUp?type=invite_member`;
    this.api
      .post(url, {
        teaming_up_id,
        member_id,
        member_role,
        status,
        sender_id,
        push_notification_id: admin_push_notification_id,
        sender_name,
        sender_display_picture,
        admin_id,
      })
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.getTeamingUpGroups(search, page, limit);
          if (status == 'sent') {
            this.toast.showToast('Invited', 'The request has been sent.');
          } else if (status == 'removed') {
            this.toast.showToast('Removed', 'Request to join group cancelled.');
          }
        },
        (err) => {
          this.toast.showToast('Error', 'Something went wrong');
        }
      );
  }

  toggleAddGroupModel(isOpen: boolean) {
    this.isAddGroupModelOpen.next(isOpen);
  }

  changeUpdateGroupModelOpen(isOpen: boolean) {
    this.isUpdateGroupModelOpen.next(isOpen);
  }

  openAddGroupModel(role) {
    this.appBll.subscriptions$.pipe(take(1)).subscribe((res: any) => {
      // let activeSubscription: any = res.subscriptions.filter(((sub: any) => sub.status === 'active' || sub?.status === 'default_active'))[0];
      let activeSubscription: any;
      if (res.subscriptions.filter(((sub: Subscription) => sub.status === 'active'))[0]) {
        activeSubscription = res.subscriptions.filter(((sub: Subscription) => sub.status === 'active'))[0];
      } else {
        activeSubscription = res.subscriptions.filter(((sub: Subscription) => sub.status === 'default_active'))[0]
      }
      if (role != 'athlete' || activeSubscription?.groups_allowed === 'unlimited' || (activeSubscription?.groups_allowed - activeSubscription?.groups_created > 0)) {
        this.toggleAddGroupModel(true);
        this.router.navigate(['/teaming-up']);
      } else if (activeSubscription) {
        this.toast.showToast('', 'You need to upgrade your subscription plan to add more Groups!', 'danger');
      } else {
        this.toast.showToast('', 'You need to add your subscription plan to add Groups!', 'danger');
      }
    });
  }

}
