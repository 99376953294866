import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, from } from "rxjs";
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { take } from 'rxjs/operators';
import { Root } from "./views/teaming-up/teaming-up.model";
import { AciScoreDetailsComponent } from "./components/aci-score-details/aci-score-details.component";
import { ModalController } from "@ionic/angular";
import { ImageSliderModelComponent } from "./components/image-slider-model/image-slider-model.component";
import { Router } from "@angular/router";
import { MenuService } from "./services/menu/menu.service";
@Injectable({
  providedIn: 'root',
})
export class AppBLL {
  //#region Loaders Observables

  private isLoading_plans: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoading_plans$: Observable<boolean> =
    this.isLoading_plans.asObservable();

  // private is_aci_loading: BehaviorSubject<boolean> =
  //   new BehaviorSubject<boolean>(false);
  // readonly is_aci_loading$: Observable<boolean> =
  //   this.is_aci_loading.asObservable();


  private subscriptions: BehaviorSubject<Root> = new BehaviorSubject<Root>(
    null
  );
  readonly subscriptions$: Observable<Root> = this.subscriptions.asObservable();


  // private user_aci: BehaviorSubject<any> = new BehaviorSubject<any>(
  //   null
  // );
  // readonly user_aci$: Observable<Root> = this.user_aci.asObservable();


  //#endregion Data Observables

  constructor(private api: ApiService, private toast: ToastService,
    private _modal: ModalController, private router: Router,
    private menu: MenuService
  ) { }

  public get_subscriptions() {
    this.isLoading_plans.next(true);
    this.api
      .get('payment?type=get_subscriptions').pipe(take(1)).subscribe(
        (res: Root) => {
          this.subscriptions.next(res);
          this.isLoading_plans.next(false);
        },
        (err) => {
          this.isLoading_plans.next(false);
        }
      );
  }


  // async openACIModel() {
  //   const m = await this._modal.create({
  //     component: AciScoreDetailsComponent,
  //     animated: true,
  //     backdropDismiss: true,
  //     cssClass: 'aciModel'
  //   });
  //   m.onDidDismiss().then(data => {
  //   });
  //   await m.present();
  // }

  // async openQuickStartModel(role) {
  //   const m = await this._modal.create({
  //     component: ImageSliderModelComponent,
  //     animated: true,
  //     backdropDismiss: true,
  //     cssClass: 'sliderModel'
  //   });
  //   m.onDidDismiss().then(data => {
  //     if (role == "athlete") {
  //       this.openACIModel();
  //     }
  //   });
  //   await m.present();
  // }

  // calculateAge(birthdate: Date): number {
  //   const today = new Date();
  //   const birthDate = new Date(birthdate);
  //   let age = today.getFullYear() - birthDate.getFullYear();
  //   const monthDiff = today.getMonth() - birthDate.getMonth();

  //   if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
  //     age--;
  //   }
  //   console.log("age", age)
  //   localStorage.setItem('age', age.toString())
  //   return age;
  // }

  // calls when auth initialized , 1. login,...
  // public get_user_aci(role, fromLogin) {
  //   this.is_aci_loading.next(true);
  //   this.api
  //     .get('reports?type=get_user_aci').pipe(take(1)).subscribe( 
  //       async (res: any) => {

  //         if (this.calculateAge(res?.is_features_reviewed?.dob) <= 12) {
  //           this.menu.disableRoutes('age_restrictions');
  //           // this.router.navigate(['profile/0']);
  //         }
  //         //  else {
  //         //   this.router.navigate(['home'], { replaceUrl: true, queryParams: { fromLogin: true}});
  //         // }

  //         this.user_aci.next(res);
  //         this.is_aci_loading.next(false);
  //         if (role == "athlete") {
  //           if (!res?.is_features_reviewed?.is_features_reviewed) {
  //             this.openQuickStartModel(role);
  //             this.post_feature_reviewed();
  //           } else {
  //             if (this.router.url === '/home' || this.router.url === '/home?fromLogin=true') {
  //               this.openACIModel();
  //             }
  //           }
  //         } else if ((!res?.is_features_reviewed?.is_features_reviewed) && fromLogin) {
  //           this.openQuickStartModel(role);
  //           this.post_feature_reviewed();
  //         }
  //       },
  //       (err) => {
  //         this.is_aci_loading.next(false);
  //       }
  //     );
  // }

  async post_feature_reviewed() {
    await this.api.post('auth?type=features-reviewed', {})
      .pipe(take(1))
      .subscribe(
        async (res) => {
        },
        async (err) => {
        }
      );

  }


}