// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'pandastronger-dev',
    appId: '1:369779512610:web:f584549598ff400435417b',
    storageBucket: 'pandastronger-dev.appspot.com',
    apiKey: 'AIzaSyDbnEx0NRj9ovvjuSmjCNAv-jQ94E5xXXQ',
    authDomain: 'pandastronger-dev.firebaseapp.com',
    messagingSenderId: '369779512610',
    vapidKey:
      'BPTsj3NkkfbspoTNBzvEJw8yHnr3_mKvE1EG4kOSL6KDF_PPgqngbC8OlEKmD8avIkSjCotFJkj7vBu2mn-pWKM',
  },
  api_url: 'https://pandastronger-dev.azure-api.net/',
  // api_url: 'http://localhost:7071/api/',
  unauth_api_url: 'https://pandastronger-dev.azure-api.net/pandastronger-dev-payment/',
  base_url: 'https://dev.pandastronger.com/',
  production: false,
  stripe_key:
    'pk_test_51KdfPuBHSiBDG4frnQ6HAr0beHF94arwfb5KFUTIZqnd0Q8OkWb3FARlNpEj7g4cbBzhT1NdfdcKvw7PIXgf6QmF009oJn9Rad',
  azure_blob: {
    blob_credentials:
                      'https://pandastrongerblobstorage.blob.core.windows.net/pictures?sp=racwdli&st=2022-09-20T11:14:02Z&se=2032-09-19T19:14:02Z&sv=2021-06-08&sr=c&sig=nR9WUS7n5uN3x0FTgUz%2Fl6BriX7flhRpGQgzxyOb0w0%3D',
    container_name: 'pictures',
    blob_image_base_url:
      'https://pandastrongerblobstorage.blob.core.windows.net/pictures/pictures/',
  },
  azure_blob_documents: {
    blob_credentials: 'https://pandastrongerblobstorage.blob.core.windows.net/documents?sp=racwdli&st=2023-03-21T10:16:22Z&se=2030-03-21T18:16:22Z&sv=2021-12-02&sr=c&sig=K2cfT6b3u7Wk1Cb5dz2l%2FOgCfQWaZQBUcDhOaz3P0ic%3D',
    container_name: 'documents',
    blob_image_base_url: 'https://pandastrongerblobstorage.blob.core.windows.net/documents/documents/',
  },

  azure_blob_profile_bio_video: {
    blob_credentials: 'https://pandastrongerblobstorage.blob.core.windows.net/profile-bio?sp=racwdli&st=2023-06-09T08:01:00Z&se=2050-06-09T16:01:00Z&sv=2022-11-02&sr=c&sig=1msQF4hiHJmC4ypntwwG%2Fle%2BQfxw62bA0VQfwQYaxVg%3D',
    container_name: 'profile-bio',
    base_url: 'https://pandastrongerblobstorage.blob.core.windows.net/profile-bio/profile-bio/',
  },
  azure_blob_workout_video: {
    blob_credentials: 'https://pandastrongerblobstorage.blob.core.windows.net/workout-highlights?sp=racwdli&st=2023-06-09T08:01:46Z&se=2050-06-09T16:01:46Z&sv=2022-11-02&sr=c&sig=EKbZv%2FEVSOiZzcxo0tj%2B7%2B8V%2BMINCQAkOWsAiTdPomM%3D',
    container_name: 'workout-highlights',
    base_url: 'https://pandastrongerblobstorage.blob.core.windows.net/workout-highlights/workout-highlights/',
  },
  azure_blob_higlight_video: {
    blob_credentials: 'https://pandastrongerblobstorage.blob.core.windows.net/workout-highlights?sp=racwdli&st=2023-06-09T08:01:46Z&se=2050-06-09T16:01:46Z&sv=2022-11-02&sr=c&sig=EKbZv%2FEVSOiZzcxo0tj%2B7%2B8V%2BMINCQAkOWsAiTdPomM%3D',
    container_name: 'workout-highlights',
    base_url: 'https://pandastrongerblobstorage.blob.core.windows.net/workout-highlights/workout-highlights/',
  },
  pusher: {
    key: 'c2144a958b8a4911e5db',
  },
  mux: {
    access_token_id: 'c72dc86a-63aa-418c-8979-c61d3e1ea205',
    secret_key: 'Fwu1RshSy+PGCR7u/0uvb3icAfJUyAKL52dPBu6rsvuO/lx2BtLd8cVcmHRXoYznZ7Yo2vjI6E+'
  },
  stripe: {
    api_key: 'sk_test_51KdfPuBHSiBDG4frn3gdPU1kRQTc1p0oYxGdu6SsdrivVmVSs8eOVlPnik4gpknRxtEIC9qCT1OFAKVXZuqsDaG200PvqVlhpm',
    product_id: 'prod_OUWUxsOY9yHocl'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
