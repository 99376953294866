import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { QuickWorkoutPage } from 'src/app/views/quick-workout/quick-workout.page';
import { Observable } from 'rxjs';
import { AppBLL } from 'src/app/app.bll';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { QuickWorkoutsBLL } from 'src/app/views/quick-workout/quick-workout.bll';
import { MenuService } from 'src/app/services/menu/menu.service';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-aci-score-details',
  templateUrl: './aci-score-details.component.html',
  styleUrls: ['./aci-score-details.component.scss'],
})
export class AciScoreDetailsComponent implements OnInit {

  constructor(private _modal: ModalController,
    private appBll: AppBLL,
    private quickWorkoutBll: QuickWorkoutsBLL,
    private menu: MenuService,
    private auth: AuthService,
    private router: Router) { }

  is_user_aci_loading$: Observable<boolean>;
  user_aci$: Observable<any>;
  user_role = '';

  ngOnInit() {
    this.is_user_aci_loading$ = this.auth.is_aci_loading$;
    this.user_aci$ = this.auth.user_aci$;

    this.auth.auth$
    .pipe(
      skipWhile((val) => !val.token),
      take(1)
    )
    .subscribe(async (user) => {
      this.user_role = user.role;
    });

  }

  /**
   * Method to Close modal
   */
  async close_modal() {
    await this._modal.dismiss();
  }

  /**
   * Method to open quick workout Modal
   */
  async addNewWorkoutData() {
    this.close_modal();
    this.menu.collapseMenu();
    this.quickWorkoutBll.toggle_add_menu(true); 
    // let m = await this._modal.create({
    //   component: QuickWorkoutPage,
    //   animated: true,
    //   backdropDismiss: false,
    //   cssClass: 'fullscreen_modal'
    // });
    // await m.present();
  }

  navigateToProfile() {
    this.router.navigate(['/profile/0']);
    this.close_modal();

  }

  navigateToWorkoutLog() {
    this.router.navigate(['/workouts/']);
    this.close_modal();

  }

  quickNavigate(url) {
    this.router.navigate([`${url}`]);
    this.close_modal();
  }

  goToDailyActivity() {
    this.menu.collapseMenu();
    this.router.navigate(['/panda/activities'], {
      queryParams: {
        stopQuickMenue: true,
        replaceUrl: true
      }
    });
    this.close_modal();
  }

}
