import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppBLL } from 'src/app/app.bll';
import { ApiService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/azure/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { MembershipBLL } from 'src/app/views/settings/components/monetization/components/membership/membership.bll';
import { Subscription } from 'src/app/views/settings/components/monetization/components/membership/membership.model';
import { highlight } from './highlight.model';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class HighlightBLL {

  //#region Loaders Observables

  private isAddHighlightModelOpen: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isAddHighlightModelOpen$: Observable<boolean> =
    this.isAddHighlightModelOpen.asObservable();

  private isAddHighlightGalleryModelOpen: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isAddHighlightGalleryModelOpen$: Observable<boolean> =
    this.isAddHighlightGalleryModelOpen.asObservable();

  private isLoadingAddHighlight: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoadingAddHighlight$: Observable<boolean> =
    this.isLoadingAddHighlight.asObservable();

  private isLoadingDeleteHighlight: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoadingDeleteHighlight$: Observable<boolean> =
    this.isLoadingDeleteHighlight.asObservable();

  private isLoadingHighlights: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoadingHighlights$: Observable<boolean> =
    this.isLoadingHighlights.asObservable();

  private isLoadingMoreHighlights: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoadingMoreHighlights$: Observable<boolean> =
    this.isLoadingMoreHighlights.asObservable();

  private highlights: BehaviorSubject<any> =
    new BehaviorSubject<any>([]);
  readonly highlights$: Observable<any> =
    this.highlights.asObservable();

  constructor(private api: ApiService,
    private toast: ToastService,
    private router: Router,
    private storage: StorageService,
    private _sanitizer: DomSanitizer,
    private appBll: AppBLL) { }

  toggleHighlightModel(isOpen: boolean) {
    this.isAddHighlightModelOpen.next(isOpen);
  }

  toggleHighlightGalleryModel(isOpen: boolean) {
    this.isAddHighlightGalleryModelOpen.next(isOpen);
    if(!isOpen){
      this.isLoadingAddHighlight.next(false);
    }

  }

  domSanitizer(url: string) {
    if (url.split('video_highlight').length > 1) {
      return url;
    }
    let testExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(shorts\/)|(watch\?))\??v?=?([^#&?]*).*/
    let regex = new RegExp(testExp);
    let updated_url;
    if (url && url?.match(regex) && url?.match(regex)[8]) {
      updated_url = 'https://www.youtube.com/embed/' + url?.match(regex)[8];
    }
    const sanitized_url = this._sanitizer.bypassSecurityTrustResourceUrl(updated_url);
    return sanitized_url;
  }

  public getHighlights(id, recent = 0, page, limit) {
    if(page > 1)
      this.isLoadingMoreHighlights.next(true);
    else {
      this.isLoadingHighlights.next(true);
      this.highlights.next([]);
    }
    let url: string;
    url = `highlights?type=highlight&user_id=${id}&recent=${recent}&page=${page}&limit=${limit}`;

    this.api.get(url).pipe(take(1)).subscribe(
      (res: any) => {
        if(page > 1)
          this.isLoadingMoreHighlights.next(false);
        else
          this.isLoadingHighlights.next(false);

        if (res?.highlights?.length > 0) {
          res?.highlights?.forEach((h, i) => {
            if (h.video_url) {
              res.highlights[i].video_url = this.domSanitizer(h.video_url);
            }
          })

          let currentVal = this.highlights.getValue();
          if(currentVal?.highlights?.length > 0){
            let _h = [];
            currentVal.highlights.forEach(h => {
              _h.push(h);
            });
            res.highlights.forEach(h => {
              _h.push(h);
            });
            res.highlights = _h;
          } 

          this.highlights.next(res);
        }

      },
      (err) => { }
    );
  }

  public createHighlight(highlight: highlight) {
    this.isLoadingAddHighlight.next(true);
    let url = `highlights?type=highlight`;
    this.api.post(url, highlight).pipe(take(1)).subscribe(
      async (response) => {
        this.isLoadingAddHighlight.next(false);
        this.getHighlights(0, 0, 1, 6);
        this.toggleHighlightModel(false);
        this.appBll.get_subscriptions();
        this.toast.showToast('Success', 'Highlight created successfully!');
      },
      (err) => { }
    );
  }

  async createGalleryHighlight(highlight: any, id, size) {
    this.isLoadingAddHighlight.next(true);
    console.log("VIDEO UPLOADING STARTED ====> ");
    let video = await this.storage.uploadHighlightVideo(
      'video_highlight_',
      highlight.video_url,
      id,
      size
    );
    console.log("VIDEO UPLOADING ENDED ====> ");
    console.log("VIDEO THUMBNAIL STARTED ====> ");
    // let video_thumbnail = await this.storage.uploadFile(
    //   'video_highlight_thumbnail_',
    //   highlight.thumbnail,
    //   id
    // );
    console.log("VIDEO THUMBNAIL ENDED ====> ");
    if (video) {
      console.log("API CALL STARTED ====> ");
      let url = `highlights?type=highlight`;
      this.api.post(url, {
        description: highlight.description,
        title: highlight.title, 
        video_url: video, 
        show_on_profile: true, 
        video_name: 'video_highlight_' + id,
        thumbnail: 'processing'
      }).pipe(take(1)).subscribe(
        async (response) => {
          console.log("API CALL COMPLETED ====> ");
          this.isLoadingAddHighlight.next(false);
          this.toggleHighlightGalleryModel(false);
          this.getHighlights(0, 0, 1, 6);
          this.appBll.get_subscriptions();
          this.toast.showToast('Success', 'Highlight created successfully!');
        },
        (err) => { 
          console.log("API CALL ERROR ====> ", JSON.stringify(err));
        }
      );
    } else {
      this.isLoadingAddHighlight.next(false);
    }
  }

  public deleteHighlight(highlightId: string, name) {
    this.isLoadingDeleteHighlight.next(true);
    this.isLoadingHighlights.next(true);
    this.api.delete(`highlights?type=highlight&id=${highlightId}`).pipe(take(1)).subscribe(
      async (response) => {
        this.isLoadingHighlights.next(false);
        this.isLoadingDeleteHighlight.next(false);
        this.getHighlights(0, 0, 1, 6);
        this.appBll.get_subscriptions();
        this.toast.showToast('Success', 'Highlight deleted successfully!');
        this.storage.deleteFile(name, 'azure_blob_higlight_video');
      },
      (err) => { }
    );
  }

  openAddHighlightModel() {
    this.router.navigate(['/profile/0']);
    this.toggleHighlightModel(true);
    // this.appBll.subscriptions$.pipe(take(1)).subscribe((res: any) => {
    //   let activeSubscription: any;
    //   if (res?.subscriptions?.filter(((sub: Subscription) => sub.status === 'active'))[0]) {
    //     activeSubscription = res?.subscriptions?.filter(((sub: Subscription) => sub.status === 'active'))[0];
    //   } else {
    //     activeSubscription = res?.subscriptions?.filter(((sub: Subscription) => sub.status === 'default_active'))[0]
    //   }
    //   if (activeSubscription?.videos_allowed === 'unlimited' || (activeSubscription?.videos_allowed - activeSubscription?.videos_created > 0)) {
    //     this.router.navigate(['/profile/0']);
    //     this.toggleHighlightModel(true);
    //   } else if (activeSubscription) {
    //     this.toast.showToast('', 'You need to upgrade your subscription plan to upload more highlights!', 'danger');
    //   } else {
    //     this.toast.showToast('', 'You need to add your subscription plan to upload highlights!', 'danger');
    //   }
    // });
  }

  openAddHighlightGalleryModel() {
    // this.toggleHighlightGalleryModel(true);


    this.appBll.subscriptions$.pipe(take(1)).subscribe((res: any) => {
      let activeSubscription: any;
      if (res?.subscriptions?.filter(((sub: any) => sub.status === 'calendar_sync_active'))[0] || res?.subscriptions?.filter(((sub: any) => sub.status === 'active'))[0]) {
        if(res?.subscriptions?.filter(((sub: Subscription) => sub.status === 'active'))[0]){
          activeSubscription = res?.subscriptions?.filter(((sub: Subscription) => sub.status === 'active'))[0];
        }else if (res?.subscriptions?.filter(((sub: Subscription) => sub.status === 'calendar_sync_active'))[0] ){
          activeSubscription = res?.subscriptions?.filter(((sub: Subscription) => sub.status === 'calendar_sync_active'))[0];
        }
      } else {
        activeSubscription = res?.subscriptions?.filter(((sub: Subscription) => sub.status === 'default_active'))[0]
      }
      if (activeSubscription?.videos_allowed === 'unlimited') {
        this.router.navigate(['/profile/0']);
        // this.toggleHighlightModel(true);
        this.toggleHighlightGalleryModel(true);

      } else if (activeSubscription) {
        this.toast.showToast('', 'You need to upgrade your subscription plan to upload direct highlights!', 'danger');
      } else {
        this.toast.showToast('', 'You need to add your subscription plan to upload direct highlights!', 'danger');
      }
    });
  }

}
