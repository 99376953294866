import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { skip, skipWhile, take } from 'rxjs/operators';
import { AppBLL } from 'src/app/app.bll';
import { AuthState } from 'src/app/services/auth/auth.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/azure/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { WorkoutDetailBLL } from './workout-detail.bll';
import { VideoUploadComponent } from '../video-upload/video-upload.component';
import { ViewHighlightVideoBLL } from 'src/app/views/profile/components/view-highlight-video/view-highlight-video.bll';
import { ViewHighlightVideoComponent } from 'src/app/views/profile/components/view-highlight-video/view-highlight-video.component';
import { video } from '../video-upload/video.model';

@Component({
  selector: 'app-workout-detail',
  templateUrl: './workout-detail.component.html',
  styleUrls: ['./workout-detail.component.scss'],
})
export class WorkoutDetailComponent implements OnInit, OnDestroy {

  auth$: Observable<AuthState>;
  current_user_id = '';

  MuxSubscription: Subscription;

  show_workout_detail$: Observable<boolean>;
  user_workout$: Observable<any>;

  isLoading$: Observable<boolean>;
  patchLoading$: Observable<boolean>;
  workouts$: Observable<any[]>;

  add_video_overlay: boolean = false;
  add_gallery_video_overlay: boolean = false;

  fileSize: any = 10;
  file: Blob;
  videoChangedEvent: any = '';
  video_url: string = '';
  gallery_video_url_data = '';
  selected_view_details: string = 'info';

  workout;

  subscriptions$: Observable<any>;

  constructor(
    private bll: WorkoutDetailBLL,
    private toast: ToastService,
    private appBll: AppBLL,
    private alertController: AlertController,
    private storage: StorageService,
    private auth: AuthService,
    private modal: ModalController,
    private videoBll: ViewHighlightVideoBLL,
    private alert: AlertController,
    private loader: LoadingController
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.storage.fileSize$.subscribe(size=>{
      this.fileSize = Number(size).toFixed(2);
    });
    this.auth$ = this.auth.auth$;

    this.auth$
    .pipe(
      skipWhile((val) => !val.token),
      take(1)
    )
    .subscribe(async (user) => {
      this.current_user_id = user.id;
    });

    this.mapObservables();
    this.subscriptions$ = this.appBll.subscriptions$;
  }

  async openDeleteVideoConfirmation(){
    const a = await this.alert.create({
      header: 'Delete Video',
      subHeader: 'Are you sure?',
      message: 'This will delete the video permanently from PandaStronger storage and will not be able to recover back.',
      animated: true,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Yes, delete',
          role: 'confirm',
          handler: () => {
            this.deleteVideo();
          }
        }
      ]
    });

    await a.present();
  }

  async deleteVideo(){
    const l = await this.loader.create({
      animated: true,
      message: 'Deleting video...',
      spinner: 'crescent',
      backdropDismiss: false,
    });

    await l.present();

    this.videoBll.deleteVideoByPlaybackID(this.workout.playback_id).then(async(res) => {
      window.location.href = window.location.origin + '/workouts';
    }).catch(async(err) => {
      this.toast.showToast('', 'There was an unexpected issue deleting the video. Please try again.', 'danger');
    }).finally(() => {
      l.dismiss();
    });
  }

  mapObservables() {
    this.show_workout_detail$ = this.bll.show_workout_detail$;
    this.user_workout$ = this.bll.user_workout$;
    this.isLoading$ = this.bll.isLoading$;
    this.patchLoading$ = this.bll.patchLoading$;
    this.workouts$ = this.bll.workouts$;

    this.user_workout$.pipe(skipWhile(w => !w), take(1)).subscribe(u => {
      this.workout = u;
      console.log(this.workout);
      setTimeout(() => {
        const muxPlayer = document.querySelector("mux-player");
        muxPlayer.setAttribute('playback-id', u.playback_id);
      }, 1000);
    });
  }

  convertToInches(feet, unit) {
    if(unit === '(Feet & Inches)') {
      return Math.trunc(feet/12) + ' ft ' + (feet)%12 + ' in';
    } else {
      return feet;
    }
  }

  markFavorite(user_workout_id: string, value: boolean) {
    this.bll.patch_workout(user_workout_id, 'favorite', {
      game_highlight: value
    });
  }

  update_video(user_workout_id: string, action: string) {
    this.bll.patch_workout(user_workout_id, action, {
      workout_evidence: this.video_url
    });
    this.show_video_update_overlay(false);
  }

  unitOfMeasurements(w) {
    return w.unit_of_measurement ? '('+w?.unit_of_measurement?.split(', ')[w?.unit_of_measurement?.split(',')?.length - 1]+')' : ''
  }

  async delete_video(user_workout_id: string,) {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to delete video?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => { },
        },
        {
          text: 'Yes',
          role: 'yes',
          handler: () => {
            this.bll.patch_workout(user_workout_id, 'remove', {
              workout_evidence: "",
              video_name: ''
            });
          },
        },
      ],
    });

    await alert.present();
  }

  close_workout_modal() {
    this.bll.clear_observables();
    this.bll.close_workout();
  }

  view_did_change_details(view) {
    this.selected_view_details = view.detail.value;
  }

  show_video_update_overlay(value: boolean) {
    this.video_url = '';
    this.add_video_overlay = value;
    // this.subscriptions$.pipe(take(1)).subscribe((res) => {
    //   let activeSubscription: any;
    //   if (res?.subscriptions?.filter(((sub: any) => sub.status === 'active'))[0]) {
    //     activeSubscription = res?.subscriptions?.filter(((sub: any) => sub.status === 'active'))[0];
    //   } else {
    //     activeSubscription = res?.subscriptions?.filter(((sub: any) => sub.status === 'default_active'))[0]
    //   }

    //   if (activeSubscription?.videos_allowed === 'unlimited' || (activeSubscription?.videos_allowed - activeSubscription?.videos_created > 0)) {
    //     this.video_url = '';
    //     this.add_video_overlay = value;
    //   } else if (activeSubscription) {
    //     this.toast.showToast('', 'You need to upgrade your subscription plan to upload more highlights!', 'danger');
    //   } else {
    //     this.toast.showToast('', 'You need to add your subscription plan to upload highlights!', 'danger');
    //   }
    // });
  }

  async update_gallery_video(user_workout_id: string, action: string) {
    this.bll.add_gallery_workout_video(user_workout_id, action, this.file, this.fileSize);
    this.show_video_update_overlay(false);
  }

  async openVideoUploadModal(user_workout_id: string){
    const m = await this.modal.create({
      component: VideoUploadComponent,
      componentProps: {
        module: 'workouts',
        user_id: this.current_user_id,
        preTitle: this.workout.title,
        preDescription: this.workout.description
      },
      backdropDismiss: false
    });

    await m.present();
    m.onDidDismiss().then(res => {
      if(res.data.action && res.data.action === 'video-created'){
        if(res.data.video_id){
          this.bll.upload_video_and_attach_workout(user_workout_id, res.data.video_id);
        }
      }
    });
  }

  async removeVideo(){

  }

  show_gallery_video_update_overlay(value: boolean, workout_id?: string) {
    
    if(!value) {
      this.gallery_video_url_data = '';
      this.video_url = '';
      this.add_gallery_video_overlay = value;
      return;
    }

    this.subscriptions$.pipe(take(1)).subscribe((res) => {
      let activeSubscription: any;
      if (res?.subscriptions?.filter(((sub: any) => sub.status === 'active'))[0]) {
        activeSubscription = res?.subscriptions?.filter(((sub: any) => sub.status === 'active'))[0];
      } else {
        activeSubscription = res?.subscriptions?.filter(((sub: any) => sub.status === 'default_active'))[0]
      }

      if (activeSubscription?.videos_allowed === 'unlimited') {
        this.video_url = '';
        //this.add_gallery_video_overlay = value;
        this.openVideoUploadModal(workout_id);
      } else if (activeSubscription) {
        this.toast.showToast('', 'You need to upgrade your subscription plan to upload direct highlights!', 'danger');
      } else {
        this.toast.showToast('', 'You need to add your subscription plan to upload direct highlights!', 'danger');
      }
    });
  }


  videoFileChanged(event) {
    const reader = new FileReader();
    this.videoChangedEvent = event;
    this.gallery_video_url_data = 'a';

    if (event.target.files && event.target.files.length) {     
      if (event?.target?.files[0]?.size/(1024*1024)  <= 100) {
        const [file] = event.target.files;
        this.file = file;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.gallery_video_url_data = reader.result as string;
        };
        this.fileSize = event?.target?.files[0]?.size;
      } else {
        this.toast.showToast(
          'Warning!',
          'More than 100 MB video is not allowed!',
          'warning'
        );
      }
    }
  }

  async openVideo() {
        // video_id: string, module: string, asset_id: string, height: number, width: number, video_type: string, title: string, description: string, playback_id: string, thumbnail_url: string, video_user_id: string, video_status: string
        let v: video = {
          video_id: this.workout.video_id,
          user_id: this.workout.video_user_id,
          module: this.workout.module,
          asset_id: this.workout.asset_id,
          height: this.workout.height,
          width: this.workout.width,
          video_type: this.workout.video_type,
          playback_id: this.workout.playback_id,
          thumbnail_url: this.workout.thumbnail_url,
          title: '',
          description: '',
          status: this.workout.video_status
        };
        const m = await this.modal.create({
          component: ViewHighlightVideoComponent,
          componentProps: {
            video: v,
            user_id: this.current_user_id,
            user_posts: true
          },
          backdropDismiss: false,
          animated: true,
          cssClass: 'video-preview'
        });
    
        await m.present();
      }



  matchYoutubeUrl(url){
    let state: boolean = false;
    if (url != undefined || url != '') {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
            state = true;
        }
        else {
            state = false;
        }
    }
    return state;
  }

}
