import { NgModule } from '@angular/core';
import { PicInitialsPipe } from './pic-initials/pic-initials.pipe';
import { DomSanitizerPipe } from './dom-sanitizer/dom-sanitizer.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { ClubFeePipe } from './club-fee/club-fee.pipe';

@NgModule({
  imports: [],
  declarations: [PicInitialsPipe, DomSanitizerPipe, TruncatePipe, ClubFeePipe],
  exports: [PicInitialsPipe, DomSanitizerPipe, TruncatePipe, ClubFeePipe],
})
export class CustomPipesModule {}