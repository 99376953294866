import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private token: string;

  constructor(private http: HttpClient, private auth: AuthService) {
    auth.auth$.subscribe((user) => {
      this.token = user.token;
    });
  }

  get(url: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.token,
    };
    return this.http.get(environment.api_url + url, {
      headers: new HttpHeaders(headers),
    });
  }

  get_without_base(url: string): Observable<any>{
    const headers = {
      'Content-Type': 'application/json'
    }
    return this.http.get(url, {
      headers: new HttpHeaders(headers),
    });
  }

  post(url: string, body: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.token,
    };
    return this.http.post(environment.api_url + url, body, {
      headers: new HttpHeaders(headers),
    });
  }

  patch(url: string, body: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.token,
    };
    return this.http.patch(environment.api_url + url, body, {
      headers: new HttpHeaders(headers),
    });
  }

  delete(url: string, body?: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.token,
      }),
      body
    };
    return this.http.delete(environment.api_url + url, options);
  }
}
