import { Injectable, Optional } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
// import { MenuService } from '../../menu/menu.service';
// import { PopoverController } from '@ionic/angular';
// import { Router } from '@angular/router';
// import { signOut, Auth } from '@angular/fire/auth';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    // @Optional() private auth: Auth,
    private AuthService: AuthService
  ) // private router: Router,
  // private menu: MenuService,
  // private popover: PopoverController
  {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // console.info(request);
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // this.logOut();
          this.refreshToken();
          return throwError(error);
        }
        return throwError(error);
      })
    );
  }

  // async logOut() {
  //   await signOut(this.auth);
  //   this.popover.dismiss();
  //   this.menu.hideMenu();
  //   this.router.navigate(['auth/login']);
  //   console.clear();
  // }

  async refreshToken() {
    this.AuthService.refreshToken();
  }
}
