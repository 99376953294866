import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ApiService } from "src/app/services/api/api.service";
import { StorageService } from "src/app/services/azure/storage/storage.service";
import { UserWorkoutsBLL } from "../workouts/workouts.bll";

@Injectable({
    providedIn: 'root'
})
export class WorkoutDetailBLL {

    private show_workout_detail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly show_workout_detail$: Observable<boolean> = this.show_workout_detail.asObservable();

    private user_workout: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    readonly user_workout$: Observable<any> = this.user_workout.asObservable();

    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();

    private workouts: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    readonly workouts$: Observable<any[]> = this.workouts.asObservable();

    private patchLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly patchLoading$: Observable<boolean> = this.patchLoading.asObservable();

    constructor(
        private api: ApiService,
        private workout_bll: UserWorkoutsBLL,
        private storage: StorageService
    ){}

    get_workout_details(user_workout_id){
        this.isLoading.next(true);
        let url = `user_workout?user_workout_id=${user_workout_id}`;
        this.api.get(url).pipe(take(1)).subscribe((res) => {
            this.workouts.next(res);
            this.isLoading.next(false);
        }, (err) => {
            this.isLoading.next(false);
        });
    }

    get_parent_workout(user_workout_id){
        let url = `user_workout?user_workout_id=${user_workout_id}&action=parent-data`;
        this.api.get(url).pipe(take(1)).subscribe((res) => {
            this.patchLoading.next(false);
            if(res.workout_evidence)
                res.workout_evidence = this.workout_bll.domSanitizer(res.workout_evidence);
            this.user_workout.next(res);
        }, (err) => {
            this.patchLoading.next(false);
        });
    }

    patch_workout(user_workout_id, action: string, data){
        this.patchLoading.next(true);
        let url = `user_workout?user_workout_id=${user_workout_id}&action=${action}`;
        this.api.patch(url, data).pipe(take(1)).subscribe((res) => {
            this.patchLoading.next(false);
            window.location.href = window.location.origin + '/workouts';

            // window.location.href = window.location.href;
            //this.get_parent_workout(user_workout_id);
        }, (err) => {
            this.patchLoading.next(false);
        });
    }

    async add_gallery_workout_video(user_workout_id, action: string, file, fileSize) {
        this.patchLoading.next(true);

        let video_url = await this.storage.uploadWorkoutVideo(
            'workout_video_',
            file,
            user_workout_id,
            fileSize
        );

        if (video_url) {
            let url = `user_workout?user_workout_id=${user_workout_id}&action=${action}`;
            this.api.patch(url, {
                workout_evidence: video_url,
                video_name: 'workout_video_'+user_workout_id
              }).pipe(take(1)).subscribe((res) => {
                // window.location.href = window.location.href;
                window.location.href = window.location.origin + '/workouts';
                this.patchLoading.next(false);
            }, (err) => {
                this.patchLoading.next(false);
            });
        } else {
            this.patchLoading.next(false);

        }



    }

    async upload_video_and_attach_workout(user_workout_id, video_id) {
        this.patchLoading.next(true);
        let url = `user_workout?user_workout_id=${user_workout_id}&action=video-id`;
        this.api.patch(url, {
            video_id: video_id
          }).pipe(take(1)).subscribe((res) => {
            window.location.href = window.location.origin + '/workouts';
            this.patchLoading.next(false);
        }, (err) => {
            this.patchLoading.next(false);
        });

    }


    clear_observables(){
        this.workouts.next([]);
        this.patchLoading.next(false);
        this.isLoading.next(false);
        this.user_workout.next(null);
    }

    show_workout(user_workout){
        this.show_workout_detail.next(true);
        this.user_workout.next(user_workout);
        this.get_workout_details(user_workout.user_workout_id);
    }

    close_workout(){
        this.show_workout_detail.next(false);
    }
}