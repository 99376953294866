import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, skipWhile, take, tap } from 'rxjs/operators';
import { NewActivityComponentBLL } from './new-activity.bll';
import { create_event, teaming_up_group } from './new-activity.model';
import moment from 'moment';
import 'moment-timezone';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CalendarComponentBLL } from '../../calendar/calendar.bll';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-new-activity',
  templateUrl: './new-activity.component.html',
  styleUrls: ['./new-activity.component.scss'],
})
export class NewActivityComponent implements OnInit, AfterViewInit {

  show_addNew$: Observable<boolean>;
  teams$: Observable<teaming_up_group[]>;

  //Loaders
  isLoading$: Observable<boolean>;
  isTeamsLoading$: Observable<boolean>;

  //Form
  form: FormGroup

  //#region State Managing Values
  for_myself: boolean = true;
  enable_notifications: boolean = true;

  show_teams_overlay: boolean = false;
  team: any = null; 
  @ViewChild('search_team') search_team: ElementRef;
  selected_team: any = [];

  start_date_picker: string = moment(new Date()).format('YYYY-MM-DD');
  end_date_picker: string =  moment(new Date()).format('YYYY-MM-DD');
  start_time_picker: string = moment(new Date()).add(1, 'day').toISOString(true);
  end_time_picker: string = moment(this.start_time_picker).add(1, 'day').add(1, 'hour').toISOString(true);
  show_weekdays: boolean = false;
  selectedWeekdays: any[] = 
  [
  {
    title: 'Sun',
    value: 0,
    selected: false,
    show: false,
    min: 'Su'
  },
  {
    title: 'Mon',
    value: 1,
    selected: false,
    show: false,
    min: 'Mo'
  },
  {
    title: 'Tue',
    value: 2,
    selected: false,
    show: false,
    min: 'Tu'
  },
  {
    title: 'Wed',
    value: 3,
    selected: false,
    show: false,
    min: 'We'
  },
  {
    title: 'Thu',
    value: 4,
    selected: false,
    show: false,
    min: 'Th'
  },
  {
    title: 'Fri',
    value: 5,
    selected: false,
    show: false,
    min: 'Fr'
  },
  {
    title: 'Sat',
    value: 6,
    selected: false,
    show: false,
    min: 'Sa'
  }
  ];
  //#endregion State Managing Values

  constructor(
    private bll: NewActivityComponentBLL,
    private fb: FormBuilder,
    private toast: ToastController,
    private router: Router
  ) { 
    this.form = this.fb.group(this.initialize_form());
    this.bll.group_activity$.subscribe(activity=>{
      if(activity?.date) {

        let h = moment(this.start.value).format('HH');
        let m = moment(this.start.value).format('mm');
        let current_d = moment(activity?.date).format('YYYY-MM-DD');
        current_d = current_d + `T${h}:${m}`;
        this.start.setValue(moment(current_d).toDate());


        let hh = moment(this.end.value).format('HH');
        let mm = moment(this.end.value).format('mm');
        let current_dd = moment(activity?.date).format('YYYY-MM-DD');
        current_dd = current_dd + `T${hh}:${mm}`;
        this.end.setValue(moment(current_dd).toDate());



        this.start_date_picker = moment(new Date(activity?.date)).format('YYYY-MM-DD');
        this.end_date_picker = moment(new Date(activity?.date)).format('YYYY-MM-DD');
        if(activity?.group?.title) {
          this.for_myself = false;
          this.selected_team.push(activity.group);
          this.teaming_up_group_id.setValue(this.selected_team);
        }
        this.bll.toggle_add_menu(true);
      }
    });
  }

  ngAfterViewInit(): void {
     fromEvent(this.search_team.nativeElement,'keyup')
      .pipe(
          filter(Boolean),
          debounceTime(150),
          distinctUntilChanged(),
          tap((text) => {
            this.search_teaming_up_groups();
          })).subscribe();
  }

  ngOnInit() {
    this.mapObservables();
  }

  mapObservables(){
    this.show_addNew$ = this.bll.show_addMenu$;
    this.isLoading$ = this.bll.isLoading$;
    this.isTeamsLoading$ = this.bll.isTeamsLoading$;
    this.teams$ = this.bll.teams$;

  }

  reset_form_and_bll(){
    this.bll.reset_teams_observable();
    this.bll.reset_event_created();
    this.form = this.fb.group(this.initialize_form());
    setTimeout(() => {
      this.weekdays.setValue('0,1,2,3,4,5,6');
    }, 0);

    // this.start_date_picker =  moment(new Date()).format('YYYY-MM-DD');
    // this.end_date_picker =  moment(new Date()).format('YYYY-MM-DD');
    let dom_document: any = document;
    dom_document.getElementById('start').value = moment(new Date()).format('YYYY-MM-DD');
    dom_document.getElementById('end').value = moment(new Date()).format('YYYY-MM-DD');

    this.start_time_picker = moment(new Date()).add(1, 'day').toISOString(true);
    this.end_time_picker = moment(this.start_time_picker).add(1, 'day').add(1, 'hour').toISOString(true);
    this.show_weekdays = false;
    this.for_myself = true;
    this.enable_notifications = true;
    this.selected_team = [];
    this.selectedWeekdays = [
      {
        title: 'Sun',
        value: 0,
        selected: false,
        show: false,
        min: 'Su'
      },
      {
        title: 'Mon',
        value: 1,
        selected: false,
        show: false,
        min: 'Mo'
      },
      {
        title: 'Tue',
        value: 2,
        selected: false,
        show: false,
        min: 'Tu'
      },
      {
        title: 'Wed',
        value: 3,
        selected: false,
        show: false,
        min: 'We'
      },
      {
        title: 'Thu',
        value: 4,
        selected: false,
        show: false,
        min: 'Th'
      },
      {
        title: 'Fri',
        value: 5,
        selected: false,
        show: false,
        min: 'Fr'
      },
      {
        title: 'Sat',
        value: 6,
        selected: false,
        show: false,
        min: 'Sa'
      }
    ];
  }

  initialize_form(){
    return {
      title: ['', [Validators.required]],
      description: [''],
      keyword_team: [''],
      teaming_up_group_id: [[]],
      type: ['program'],
      start: [moment(new Date()).toDate()],
      end: [moment(new Date()).add(1, 'hour').toDate()],
      weekdays: ['0,1,2,3,4,5,6'],
      cover_image: [''],
      location_coordinates: [''],
      location_description: [''],
      location_address: ['']
    }
  }

  close_add_menu(){
    this.reset_form_and_bll();
    this.bll.toggle_add_menu(false);
  }

  change_for_myself() {
    this.for_myself = !this.for_myself;
    this.selected_team = [];
    this.teaming_up_group_id.reset();
  }

  toggle_notification(){
    this.enable_notifications = !this.enable_notifications;
  }

  change_type(type: string){
    this.type.setValue(type);
  }
  
  search_teaming_up_groups(){
    this.bll.get_teaming_up_groups_by_search(this.keyword_team.value);
  }

  select_teaming_up_group(team: teaming_up_group){
    if(team){
      this.selected_team.push(team);
      this.teaming_up_group_id.setValue(this.selected_team);
    }
    this.show_teams_overlay = false;
    this.keyword_team.reset();
    this.bll.reset_teams_observable();
  }

  selectWeekday(day: number){
    let selectedDays: number[] = [];
    this.selectedWeekdays.forEach(w => {
      if(day === w.value){
        w.selected = !w.selected
      } 
      if(w.selected){
        selectedDays.push(w.value);
      }
    });
    let d:string = selectedDays.join(',');
    this.weekdays.setValue(d);
  }


  startDateInputMask = createMask<Date>({ 
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
    parser: (value: string) => {
      const values = value.split('/');
      const year = +values[2];
      const month = +values[1] - 1;
      const date = +values[0];
      return new Date(year, month, date);
    },
  });

  endDateInputMask = createMask<Date>({ 
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
    parser: (value: string) => {
      const values = value.split('/');
      const year = +values[2];
      const month = +values[1] - 1;
      const date = +values[0];
      return new Date(year, month, date);
    },
  });


  date_change(type: string, event: any) {
    // let targetDate = moment(event.target.value, 'DD/MM/YYYY').toDate();
    switch(type){
      case 'start':
        let h = moment(this.start.value).format('HH');
        let m = moment(this.start.value).format('mm');
        let current_d = moment(event.target.value).format('YYYY-MM-DD');
        current_d = current_d + `T${h}:${m}`;
        this.start.setValue(moment(current_d).toDate());
        if(moment(this.end.value).isBefore(moment(event.target.value))) {
          this.end_date_picker = moment(event.target.value).format('YYYY-MM-DD');
          this.end.setValue(moment(current_d).add(1, 'hour').toDate());
        }
        break;
      case 'end':
        let hh = moment(this.end.value).format('HH');
        let mm = moment(this.end.value).format('mm');
        let current_dd = moment(event.target.value).format('YYYY-MM-DD');
        current_dd = current_dd + `T${hh}:${mm}`;
        this.end.setValue(moment(current_dd).toDate());
        break;
    }
    let difference = moment(this.end.value).diff(this.start.value, 'days');
    this.show_weekdays = false;
    for(let v of this.selectedWeekdays){
      v.selected = false;
      v.show = false;
      this.weekdays.reset();
    }

    if(difference > 0){
      this.show_weekdays = true;
      setTimeout(() => {
        document.getElementById('handleActivityScroll')?.scrollTo(0, 1000)
      }, 0);
    } else {
      this.show_weekdays = false;
      this.weekdays.setValue('0,1,2,3,4,5,6');
    }

    if(difference >= 7){
      for(let w of this.selectedWeekdays){
        w.show = true;
        w.selected = true;
        this.weekdays.setValue('0,1,2,3,4,5,6');
      }
    } else if(difference > 0){
      let wd:string[] = [];
      let init_date = moment(this.start.value);
      for(let i = 0; i <= difference; i++){
        wd.push(moment.weekdaysMin((init_date.day() + i) % 7));
      }
      for(let w of this.selectedWeekdays){
        for(let v of wd){
          if(w.min === v){
            w.show = true;
            w.selected = true;
          }
        }
      }
    }

    let fw = [];
    for(let w of this.selectedWeekdays){
      if(w.selected){
        fw.push(w.value);
      }
    }
    this.weekdays.setValue(fw.join(','));
  }

  time_change(type: string, event: any){
    switch(type){
      case 'start':
        let h = moment(event.detail.value).format('HH');
        let m = moment(event.detail.value).format('mm');
        let current_d = moment(this.start.value).format('YYYY-MM-DD');
        current_d = current_d + `T${h}:${m}`;
        this.start.setValue(moment(current_d).toDate());
        moment(this.start_time_picker).add(1, 'day').add(1, 'hour');
        let time_checker_start = moment(this.start.value).format('YYYY-MM-DD') + 'T' + moment(this.start.value).format('HH:mm:ss');
        let time_checker_end = moment(this.start.value).format('YYYY-MM-DD') + 'T' + moment(this.end.value).format('HH:mm:ss');
        if(moment(time_checker_end).isSameOrBefore(time_checker_start, 'minutes')) {
          this.end_time_picker = moment(this.end.value).format('YYYY-MM-DD') + 'T' + moment(this.end.value).add(1, 'hour').format('HH:mm:ss');
          this.end.setValue(moment(this.end.value).format('YYYY-MM-DD') + 'T' + moment(this.end.value).add(1, 'hour').format('HH:mm:ss'));
        }
        break;
      case 'end':
        let hh = moment(event.detail.value).format('HH');
        let mm = moment(event.detail.value).format('mm');
        let current_dd = moment(this.end.value).format('YYYY-MM-DD');
        current_dd = current_dd + `T${hh}:${mm}`;
        this.end.setValue(moment(current_dd).toDate());
        break;
    }
  }

  async create_event(){
    let time_checker_start = moment(this.start.value).format('YYYY-MM-DD') + 'T' + moment(this.start.value).format('HH:mm:ss');
    let time_checker_end = moment(this.start.value).format('YYYY-MM-DD') + 'T' + moment(this.end.value).format('HH:mm:ss');

    if(moment(this.end.value).isSameOrBefore(this.start.value, 'minutes') || moment(time_checker_end).isSameOrBefore(time_checker_start, 'minutes')){
      const t = await this.toast.create({
        message: 'End time cannot be before start time. Please adjust and try again',
        buttons: [{
          role: 'cancel',
          text: 'Ok'
        }],
        duration: 3000,
        color: 'warning',
        position: 'bottom'
      });
      await t.present();
    } else if (this.form.invalid){
      const t = await this.toast.create({
        message: 'Please enter all required information to create session',
        buttons: [{
          role: 'cancel',
          text: 'Ok'
        }],
        duration: 3000,
        color: 'warning',
        position: 'bottom'
      });
      await t.present();
    } else {
      if(this.teaming_up_group_id?.value?.length > 0) {
        this.teaming_up_group_id.value.forEach((team, index)=> {
          let new_event: create_event = {
            title: this.title.value,
            description: this.description.value,
            start: moment(this.start.value).format('YYYY-MM-DDTHH:mm:ssZ'),
            end: moment(this.end.value).format('YYYY-MM-DDTHH:mm:ssZ'),
            teaming_up_group_id: team.id,
            type: this.type.value,
            weekdays: this.weekdays.value === "" ? '0,1,2,3,4,5,6' : this.weekdays.value,
            notify_member: this.enable_notifications
          };
    
          this.bll.create_new_event(new_event, index+1 === this.teaming_up_group_id.value.length? true: false);
        });

        this.reset_form_and_bll();
        this.bll.toggle_add_menu(false);

      } else {
        let new_event: create_event = {
          title: this.title.value,
          description: this.description.value,
          start: moment(this.start.value).format('YYYY-MM-DDTHH:mm:ssZ'),
          end: moment(this.end.value).format('YYYY-MM-DDTHH:mm:ssZ'),
          teaming_up_group_id: '',
          type: this.type.value,
          weekdays: this.weekdays.value === "" ? '0,1,2,3,4,5,6' : this.weekdays.value,
          notify_member: this.enable_notifications
        };
  
        this.bll.create_new_event(new_event);
        
        this.bll.event_created$.pipe(skipWhile(val => !val), take(1)).subscribe(res => {
          this.reset_form_and_bll();
          this.bll.toggle_add_menu(false);
          this.bll.attach_event_occurrence({
            title: new_event.title,
            start: new_event.start,
            end: new_event.end,
            type: new_event.type,
            event_occurrence_id: res.split(',')[0],
            event_id: res.split(',')[1]
          });
          // this.router.navigate(['calendar']);
        });
      }
      // let new_event = {
      //   title: this.title.value,
      //   start: moment(this.start.value).format('YYYY-MM-DDTHH:mm:ssZ'),
      //   end: moment(this.end.value).format('YYYY-MM-DDTHH:mm:ssZ'),
      //   type: this.type.value,
      // };

  
    }
  } 

  //#region FORM GETTERS

  get title(){
    return this.form.get('title');
  }

  get description(){
    return this.form.get('description');
  }

  get teaming_up_group_id(){
    return this.form.get('teaming_up_group_id');
  }

  get weekdays(){
    return this.form.get('weekdays');
  }

  get start(){
    return this.form.get('start');
  }

  get end(){
    return this.form.get('end');
  }

  get type(){
    return this.form.get('type');
  }

  get keyword_team(){
    return this.form.get('keyword_team');
  }

  //#endregion FORM GETTERS

}
