import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ApiService } from "src/app/services/api/api.service";
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { ToastController } from "@ionic/angular";
import { StorageService } from "src/app/services/azure/storage/storage.service";

@Injectable({
    providedIn: 'root'
})
export class UserWorkoutsBLL {

    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();

    private isMoreLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isMoreLoading$: Observable<boolean> = this.isMoreLoading.asObservable();

    private workouts: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    readonly workouts$: Observable<any> = this.workouts.asObservable();

    private dates: BehaviorSubject<Date[]> = new BehaviorSubject<Date[]>([]);
    readonly dates$: Observable<Date[]> = this.dates.asObservable();

    constructor(
        private api: ApiService,
        private _sanitizer: DomSanitizer,
        private toast: ToastController,
        private storage: StorageService
    ){}

    get_workouts(user_id: string, page: number, limit: number, filter: string, home?: boolean){
        let _workouts = this.workouts.getValue();
        let _dates = this.dates.getValue();
        let _unique_dates = [];

        if(_workouts){
            this.isMoreLoading.next(true);
        } else {
            this.workouts.next(null);
            this.dates.next([]);
            this.isLoading.next(true);
        }

        let url = `user_workout?user_id=${user_id}&page=${page}&limit=${limit}`;

        if(filter !== 'all'){
            url += `&filter=${filter}`;
        }

        if(home){
            url += `&home=true`;
        }

        this.api.get(url).pipe(take(1)).subscribe((res) => {
           
            if(res.workouts.length > 0){
                res.workouts.forEach((w, i) => {
                    if (w.workout_evidence) {
                        if (w.workout_evidence?.split('workout_video')?.length > 1) {
                            res.workouts[i].workout_evidence = w.workout_evidence;
                        } else {
                            res.workouts[i].workout_evidence = this.domSanitizer(w.workout_evidence);
                        }
                    }
                })
            }

            _unique_dates = this.get_unique_dates(res.workouts);

            if(_workouts){
                for(let w of res.workouts){
                    _workouts.workouts.push(w);
                }
            } else {
                _workouts = res;
            }

            if(_dates){
                for(let d of _unique_dates){
                 _dates.push(d);   
                }
            } else {
                _dates = _unique_dates;
            }

            _dates = _dates.filter((date, i, self) => self.findIndex(d => d.getTime() === date.getTime()) === i);

            this.workouts.next(_workouts);
            this.dates.next(_dates);
            this.isLoading.next(false);
            this.isMoreLoading.next(false);
        }, (err) => {
            this.isLoading.next(false);
            this.isMoreLoading.next(false);
        });
    }

  public add_interaction(user_post_id: string, interaction: string, action: string, user_name = '') {
    let url = `user_workout?type=interaction`;
    let data = {
      user_workout_id: user_post_id,
      interaction: interaction,
      action: action
    }
    let _p = this.workouts.getValue().workouts;
    _p.forEach(p => {
      if (p.user_workout_id === user_post_id) {
        if (action === 'remove') {
          p.liked_by?.splice(p?.liked_by?.indexOf(user_name), 1)
          if (p.like_count > 0)
            p.like_count--;
          if (p.self_like > 0)
            p.self_like--;
        } else {
          p.like_count++;
          p.self_like++;
          p.liked_by.push(user_name);
          if (p.self_fire > 0) {
            p.self_fire--;
            p.fire_count--;
          }

        }
      }
    });

    this.workouts.next({total: _p.length, workouts: _p});

    this.api.post(url, data).pipe(take(1)).subscribe((res) => { }, (err) => { });
  }

    delete_workouts(workout_id, video_name){
        this.api.delete(`user_workout?id=${workout_id}`).pipe(take(1)).subscribe(async (res) => {
            this.isLoading.next(false);
            this.storage.deleteFile(video_name, 'azure_blob_workout_video');
            const t = await this.toast.create({
                header: 'Deleted',
                message: `Workout has been deleted successfully.`,
                duration: 5000,
                color: 'success'
            });
            window.location.reload();
            await t.present();
        }, (err) => {
            this.isLoading.next(false);
        });
    }

    domSanitizer(url: string){
        let testExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(shorts\/)|(watch\?))\??v?=?([^#&?]*).*/
        let regex = new RegExp(testExp);
        let updated_url;
        if (url && url?.match(regex) && url?.match(regex)[8]) {
          updated_url = 'https://www.youtube.com/embed/'+url?.match(regex)[8];
        }
        const sanitized_url = this._sanitizer.bypassSecurityTrustResourceUrl(updated_url);
        return sanitized_url;
    }

    get_unique_dates(res: any[]): Date[]{
        let dates_raw: any[] = [...new Set(res.map(item => moment(item.created_at).format('YYYY-MM-DD')))];
        dates_raw = dates_raw.sort((a,b) => { return moment(b).diff(moment(a)) });

        let dates: Date[] = dates_raw.map((d) => {
            return moment(d).toDate();
        });

        return dates;
    }

    clear_observables(){
        this.workouts.next(null);
        this.dates.next([]);
    }

    change_filter(){
        this.workouts.next(null);
        this.dates.next([]);
        this.isMoreLoading.next(null);
    }

}
