import { Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})

export class StorageService {

  private fileSize: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);
  readonly fileSize$: Observable<number> =
    this.fileSize.asObservable();

  constructor() { }


  public async deleteFile(file_name: string, blob_name): Promise<boolean>{
    const blob = new BlobServiceClient(environment[blob_name].blob_credentials);
    const container = blob.getContainerClient(
      environment[blob_name].container_name
    );
    const blockBlob = container.getBlobClient(file_name);
    return blockBlob.delete({
      deleteSnapshots: 'include'
    }).then(s => {
      return true;
    }).catch(e => {
      return false;
    });
  }


  public async uploadFile(type: string, content: any, id: string, ext?: string) { 
    const blob = new BlobServiceClient(environment.azure_blob.blob_credentials);
    const container = blob.getContainerClient(
      environment.azure_blob.container_name
    );
    let file_name = type + id;
    file_name = ext ? file_name + ext : file_name;
    const blockBlob = container.getBlockBlobClient(file_name);
    const uploadFileResp = await blockBlob.uploadData(content, {
      blobHTTPHeaders: { blobContentType: content.type },
    });
    return environment.azure_blob.blob_image_base_url + file_name;
  }

  public async uploadDocument(type: string, content: any, id: string) {
    const blob = new BlobServiceClient(environment.azure_blob_documents.blob_credentials);
    const container = blob.getContainerClient(
      environment.azure_blob_documents.container_name
    );
    const file_name = type + id;
    const blockBlob = container.getBlockBlobClient(file_name);
    const uploadFileResp = await blockBlob.uploadData(content, {
      blobHTTPHeaders: { blobContentType: content.type },
    });
    return environment.azure_blob_documents.blob_image_base_url + file_name;
  }

  public async uploadBioVideo(type: string, content: any, id: string, currentFile = 1) {
    const blob = new BlobServiceClient(environment.azure_blob_profile_bio_video.blob_credentials);
    const container = blob.getContainerClient(
      environment.azure_blob_profile_bio_video.container_name
    );
    const file_name = type + id;
    const blockBlob = container.getBlockBlobClient(file_name);
    const uploadFileResp = await blockBlob.uploadData(content, {
      blobHTTPHeaders: { blobContentType: content.type },
      onProgress: (ev) => {
        this.fileSize.next((ev.loadedBytes/currentFile)*100);
      },
    });

    return environment.azure_blob_profile_bio_video.base_url + file_name;
  }

  public async uploadWorkoutVideo(type: string, content: any, id: string, currentFile = 1) {
    const blob = new BlobServiceClient(environment.azure_blob_workout_video.blob_credentials);
    const container = blob.getContainerClient(
      environment.azure_blob_workout_video.container_name
    );
    const file_name = type + id;
    const blockBlob = container.getBlockBlobClient(file_name);
    const uploadFileResp = await blockBlob.uploadData(content, {
      blobHTTPHeaders: { blobContentType: content.type },
      onProgress: (ev) => {
        this.fileSize.next((ev.loadedBytes/currentFile)*100);
      },

    });
    return environment.azure_blob_workout_video.base_url + file_name;
  }

  public async uploadHighlightVideo(type: string, content: any, id: string, currentFile = 1) {
    const blob = new BlobServiceClient(environment.azure_blob_higlight_video.blob_credentials);
    const container = blob.getContainerClient(
      environment.azure_blob_higlight_video.container_name
    );
    const file_name = type + id;
    const blockBlob = container.getBlockBlobClient(file_name);
    const uploadFileResp = await blockBlob.uploadData(content, {
      blobHTTPHeaders: { blobContentType: content.type },
      onProgress: (ev) => {
        this.fileSize.next((ev.loadedBytes/currentFile)*100);
      },
    });
    
    return environment.azure_blob_higlight_video.base_url + file_name;
  }

}

export class BlobUploadForEditorService {

  loader;
  xhr: XMLHttpRequest;
  file_name: string;

  constructor(loader) {
    this.loader = loader;
    this.file_name = "sportswriters_image_" + new Date().getTime();
  }

  upload() {
    return this.loader.file.then(file => new Promise((resolve, reject) => {
      this._initRequest(file);
      this._initListeners(resolve, reject, file);
      this._sendRequest(file);
    }));
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest(file) {
    let regex = new RegExp('[^.]+$');
    let extension = file.name.match(regex);
    this.file_name = this.file_name + '.' + extension;
    const xhr = this.xhr = new XMLHttpRequest();
    const blob = new BlobServiceClient(environment.azure_blob.blob_credentials);
    const container = blob.getContainerClient(environment.azure_blob.container_name);
    const blockBlob = container.getBlockBlobClient(this.file_name);
    let url = blockBlob.url;
    xhr.open('PUT', url, true);
    xhr.responseType = 'json';
  }

  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
    xhr.setRequestHeader('Content-Type', file.type);

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      resolve({
        default: environment.azure_blob.blob_image_base_url + this.file_name
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  async _sendRequest(file: Blob) {
    this.xhr.send(file);
  }

  encodeImageFileAsURL(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (event) => {
        resolve(btoa(event.target.result.toString()));
      };
    });
  }



}
