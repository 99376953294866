import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ApiService } from "src/app/services/api/api.service";
import { create_event, teaming_up_group } from "./new-activity.model";
import moment from 'moment';
import 'moment-timezone';

@Injectable({
    providedIn: "root"
})
export class NewActivityComponentBLL {

    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();

    private isTeamsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isTeamsLoading$: Observable<boolean> = this.isTeamsLoading.asObservable();

    private event_created: BehaviorSubject<string> = new BehaviorSubject<string>('');
    readonly event_created$: Observable<string> = this.event_created.asObservable();

    private event: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    readonly event$: Observable<any> = this.event.asObservable();

    private teams: BehaviorSubject<teaming_up_group[]> = new BehaviorSubject<teaming_up_group[]>([]);
    readonly teams$: Observable<teaming_up_group[]> = this.teams.asObservable();
    
    private show_addMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly show_addMenu$: Observable<boolean> = this.show_addMenu.asObservable();

    private group_activity: BehaviorSubject<any> = new BehaviorSubject<any>({});
    readonly group_activity$: Observable<any> = this.group_activity.asObservable();

    constructor(
        private api: ApiService,
        private toast: ToastController
    ){}

    toggle_add_menu(show: boolean){
        this.show_addMenu.next(show);
    }

    update_group_activity(activity) {
        this.group_activity.next(activity);
    }

    get_teaming_up_groups_by_search(search: string){
        if(search){
            this.isTeamsLoading.next(true);
            let url = `teamingUp?type=get_groups&search=${search}&page=1&limit=10&admin_only=true`;
            
            this.api.get(url).pipe(take(1)).subscribe((res: any) => {
                this.isTeamsLoading.next(false);
                this.teams.next(res.groups);
            }, (err) => {
                this.isTeamsLoading.next(false);
            })
        } else {
            this.teams.next([]);
        }
    }

    create_new_event(event: create_event, show_toast = true){
        this.isLoading.next(true);
        let url = `events?action=create-event&timezone=${moment.tz.guess()}`;

        this.api.post(url, event).pipe(take(1)).subscribe(async(res: any) => {
            this.isLoading.next(false);
            if(show_toast) {
                const t = await this.toast.create({
                    header: 'Session created successfully',
                    message: `Session ${event.title} has been create successfully.`,
                    duration: 5000,
                    color: 'success'
                });
                await t.present();
            }
            this.event_created.next(`${res.data.id},${res.data.event_id}`);
        }, async (err) => {
            this.isLoading.next(false);
            const t = await this.toast.create({
                header: 'Error',
                message: `There was an issue creating this event. Please try again.`,
                duration: 5000,
                color: 'danger'
            });
            await t.present();
        });
    }

    reset_event_created(){
        this.event_created.next('');
    }

    reset_teams_observable(){
        this.teams.next([]);
    }

    attach_event_occurrence(event:any){
        this.event.next(event);
    }

    reset_event_occurrence(){
        this.event.next(null);
    }

}