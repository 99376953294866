import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ApiService } from "src/app/services/api/api.service";
import { video } from "../../../../components/video-upload/video.model";

@Injectable({
    providedIn: 'root'
})
export class ViewHighlightVideoBLL {

    constructor(
        private api: ApiService
    ){}

    public deleteVideo(video: video): Promise<any>{
        let url = `video-management?video_id=${video.id}&asset_id=${video.asset_id}`;
        return this.api.delete(url).toPromise();
    }

    public deleteVideoByPlaybackID(playback_id: string): Promise<any>{
        let url = `video-management?playback_id=${playback_id}`;
        return this.api.delete(url).toPromise();
    }
}