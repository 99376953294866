import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import {
  // requestsList,
  Notifications,
  notificationCount,
} from './notifications.model';
import { ToastService } from 'src/app/services/toast/toast.service';
@Injectable({
  providedIn: 'root',
})
export class NotificationsBLL {
  //#region Loaders Observables

  private isLoading_requests: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoading_requests$: Observable<boolean> =
    this.isLoading_requests.asObservable();

  private isLoading_notifications: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoading_notifications$: Observable<boolean> =
    this.isLoading_notifications.asObservable();

  private isLoading_acceptRequest: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoading_acceptRequest$: Observable<boolean> =
    this.isLoading_acceptRequest.asObservable();

  private isLoading_rejectRequest: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoading_rejectRequest$: Observable<boolean> =
    this.isLoading_rejectRequest.asObservable();

    private isLoadingFollowback: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoadingFollowback$: Observable<boolean> =
    this.isLoadingFollowback.asObservable();

  //#endregion Loaders Observables

  //#region Data Observables

  // private requests: BehaviorSubject<requestsList> =
  //   new BehaviorSubject<requestsList>(null);
  // readonly requests$: Observable<requestsList> = this.requests.asObservable();

  private notificationsCount: BehaviorSubject<notificationCount> =
    new BehaviorSubject<notificationCount>(null);
  readonly notificationsCount$: Observable<notificationCount> =
    this.notificationsCount.asObservable();

  private followBack: BehaviorSubject<any> =
    new BehaviorSubject<any>([]);
  readonly followBack$: Observable<any> =
    this.followBack.asObservable();

  private notifications: BehaviorSubject<Notifications> =
    new BehaviorSubject<Notifications>(null);
  readonly notifications$: Observable<Notifications> =
    this.notifications.asObservable();
  //#endregion Data Observables

  constructor(private api: ApiService, private toast: ToastService) {}

  // public get_requests(user_id: string) {
  //   this.isLoading_requests.next(true);

  //   let url = `community?type=all_requests&id=${user_id}`;
  //   this.api
  //     .get(url)
  //     .pipe(take(1))
  //     .subscribe(
  //       (res: requestsList) => {
  //         this.requests.next(res);
  //         this.isLoading_requests.next(false);
  //       },
  //       (err) => {}
  //     );
  // }
  //   Accept | Reject Request

  public accept_reject_request(
    sender_id: string,
    receiver_id: string,
    status: string,
    push_notification_id: string,
    followRequest: boolean,
    sender_name: string
  ) {
    if (status === 'accepted') {
      this.isLoading_acceptRequest.next(true);
    } else {
      this.isLoading_rejectRequest.next(true);
    } 

    let url = `community?type=response`;
    this.api
      .post(url, {
        sender_id,
        receiver_id,
        status,
        push_notification_id,
        sender_name
      })
      .subscribe(async (response) => {
        // Refresh Notification temporary!
        if (!followRequest) {
          this.get_notifications();
        } else {
          let followbacks: any = this.followBack.getValue();
          followbacks.push(sender_id);
          this.followBack.next(followbacks);      
        }
        this.toast.showToast('', response.message);
      });
    setTimeout(() => {
      if (status === 'accepted') {
        this.isLoading_acceptRequest.next(false);
      } else {
        this.isLoading_rejectRequest.next(false);
      }
    }, 1000);
  }

  public get_notification_count() {
    let url = `community?type=notification_count`;
    this.api
      .get(url)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.notificationsCount.next(res);
        },
        (err) => {
          console.log('err', err);
        }
      );
  }

  public get_notifications() {
    this.isLoading_notifications.next(true);

    let url = `notification?type=notifications`;
    this.api
      .get(url)
      .pipe(take(1))
      .subscribe(
        (res: Notifications) => {
          this.notifications.next(res);
          this.isLoading_notifications.next(false);
          this.followBack.next([]);
        },
        (err) => {
          console.log('err', err);
          this.isLoading_notifications.next(false);
        }
      );
  }

  public requestAction(teaming_up_id: string, id: string, status: string, push_notification_id: string, title: string) {
    let url = `teamingUp?type=invite_response&id=${teaming_up_id}`;
    if (status === 'accepted') {
      this.isLoading_acceptRequest.next(true);
    } else {
      this.isLoading_rejectRequest.next(true);
    }

    this.api
      .patch(url, {
        id,
        status,
        push_notification_id,
        title
      })
      .subscribe(
        async (response) => {
          if (status == 'accepted') {
            this.toast.showToast('Request!', 'Request accepted successfully.');
            this.isLoading_acceptRequest.next(false);
          } else if (status == 'rejected') {
            this.toast.showToast('Request!', 'Request rejected successfully.');
            this.isLoading_rejectRequest.next(false);
          }
          this.get_notifications();
        },
        async (err) => {
          console.log('HTTP Error', err);
          this.toast.showToast('Error!', 'Something went wrong!', 'danger');
        }
      );
    setTimeout(() => {
      if (status === 'accepted') {
        this.isLoading_acceptRequest.next(false);
      } else {
        this.isLoading_rejectRequest.next(false);
      }
    }, 3000);
  }

  public send_request(
    sender_id,
    receiver_id,
    status,
    sender_name,
    sender_display_picture,
    push_notification_id
  ) {
    this.isLoadingFollowback.next(true);
    let url = `community?type=request`;
    this.api
      .post(url, {
        sender_id,
        receiver_id,
        status,
        sender_name,
        sender_display_picture,
        push_notification_id,
      })
      .subscribe(async (response) => {
        this.toast.showToast('', response.message);
        this.isLoadingFollowback.next(false);
        this.get_notifications();
      });
  }
}
