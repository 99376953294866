import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-image-slider-model',
  templateUrl: './image-slider-model.component.html',
  styleUrls: ['./image-slider-model.component.scss'],
})
export class ImageSliderModelComponent implements OnInit {

  constructor(private modal: ModalController, public platform: PlatformService
  ) { }

  @ViewChild(IonSlides) slides: IonSlides;

  // isAndroid = false;
  isIOS = false;

  slideOpts = {
    initialSlide: 0,
    speed: 2000,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  ngOnInit() { 
    console.log("is android", this.platform.isIOSPlatform() )
    // this.isAndroid = this.platform.isAndroidPlatform();
    this.isIOS = this.platform.isIOSPlatform();
  }

  slideClick(event: any) {
    // Get the index of the clicked bullet
    const clickedBulletIndex = event.target.getAttribute('data-bullet');

    // Move to the corresponding slide
    if (clickedBulletIndex !== null) {
      this.slides.slideTo(parseInt(clickedBulletIndex, 10));
    }
  }

  async close_modal() {
    // let currentRout = window.location.href;
    // let isAuthNeedResset = currentRout.split('auth/login')?.length > 1;
    // if (isAuthNeedResset) {
    //   window.location.href = window.location.origin + '/home';
    // } else {
      await this.modal.dismiss();
    // }
  }

}
