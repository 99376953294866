import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { theme } from './theme.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  themes: BehaviorSubject<theme[]> = new BehaviorSubject<theme[]>([]);
  readonly themes$: Observable<theme[]> = this.themes.asObservable();

  currentTheme: BehaviorSubject<string> = new BehaviorSubject<string>('default');
  readonly currentTheme$: Observable<string> = this.currentTheme.asObservable();

  constructor() { 
    this.getAllThemes();
    this.setTheme();
  }

  async getAllThemes(){
    this.themes.next([
      {
        name: 'default',
        color: '#f3954f'
      },
      {
        name: 'red',
        color: '#eb445a'
      },
      {
        name: 'neon-green',
        color: '#21c392'
      },
      {
        name: 'pink',
        color: '#f18ab2'
      },
      {
        name: 'blue',
        color: '#3179e8'
      }
    ]);
  }

  async setTheme(color?: string){
    let theme = await localStorage.getItem('theme');
    if(theme && theme !== 'undefined'){
      this.currentTheme.next(theme);
      switch(theme){
        case 'default':
          document.documentElement.style.setProperty('--default-base', '#f3954f');
          document.documentElement.style.setProperty('--default-shade', '#e66b10');
          document.documentElement.style.setProperty('--default-tint', '#f5a367');
          document.documentElement.style.setProperty('--default-light', '#faceaf');
          document.documentElement.style.setProperty('--default-text', '#FFFFFF');
          document.documentElement.style.setProperty('--default-low-opacity', '#f3954f66');
          break;
        case 'red':
          document.documentElement.style.setProperty('--default-base', '#eb445a');
          document.documentElement.style.setProperty('--default-shade', '#cf3c4f');
          document.documentElement.style.setProperty('--default-tint', '#ed576b');
          document.documentElement.style.setProperty('--default-light', '#fad2d7');
          document.documentElement.style.setProperty('--default-text', '#FFFFFF');
          document.documentElement.style.setProperty('--default-low-opacity', '#eb445a66');
          break;
        case 'neon-green':
          document.documentElement.style.setProperty('--default-base', '#21c392');
          document.documentElement.style.setProperty('--default-shade', '#1a9771');
          document.documentElement.style.setProperty('--default-tint', '#4fe1b5');
          document.documentElement.style.setProperty('--default-light', '#d1f7ec');
          document.documentElement.style.setProperty('--default-text', '#FFFFFF');
          document.documentElement.style.setProperty('--default-low-opacity', '#21c39266');
          break;
        case 'pink':
          document.documentElement.style.setProperty('--default-base', '#f18ab2');
          document.documentElement.style.setProperty('--default-shade', '#e94685');
          document.documentElement.style.setProperty('--default-tint', '#f4a1c1');
          document.documentElement.style.setProperty('--default-light', '#f9cedf');
          document.documentElement.style.setProperty('--default-text', '#FFFFFF');
          document.documentElement.style.setProperty('--default-low-opacity', '#f18ab266');
          break;
        case 'blue':
          document.documentElement.style.setProperty('--default-base', '#3179e8');
          document.documentElement.style.setProperty('--default-shade', '#1555b8');
          document.documentElement.style.setProperty('--default-tint', '#4888eb');
          document.documentElement.style.setProperty('--default-light', '#bbd3f8');
          document.documentElement.style.setProperty('--default-text', '#FFFFFF');
          document.documentElement.style.setProperty('--default-low-opacity', '#3179e866');
          break;
      }
    }

    if(color){
      if(color !== theme && color !== 'undefined'){
        await localStorage.setItem('theme', color);
        this.currentTheme.next(color);
        switch(color){
          case 'default':
            document.documentElement.style.setProperty('--default-base', '#f3954f');
            document.documentElement.style.setProperty('--default-shade', '#e66b10');
            document.documentElement.style.setProperty('--default-tint', '#f5a367');
            document.documentElement.style.setProperty('--default-light', '#faceaf');
            document.documentElement.style.setProperty('--default-text', '#FFFFFF');
            document.documentElement.style.setProperty('--default-low-opacity', '#f3954f66');
            break;
          case 'red':
            document.documentElement.style.setProperty('--default-base', '#eb445a');
            document.documentElement.style.setProperty('--default-shade', '#cf3c4f');
            document.documentElement.style.setProperty('--default-tint', '#ed576b');
            document.documentElement.style.setProperty('--default-light', '#fad2d7');
            document.documentElement.style.setProperty('--default-text', '#FFFFFF');
            document.documentElement.style.setProperty('--default-low-opacity', '#eb445a66');
            break;
          case 'neon-green':
            document.documentElement.style.setProperty('--default-base', '#21c392');
            document.documentElement.style.setProperty('--default-shade', '#1a9771');
            document.documentElement.style.setProperty('--default-tint', '#4fe1b5');
            document.documentElement.style.setProperty('--default-light', '#d1f7ec');
            document.documentElement.style.setProperty('--default-text', '#FFFFFF');
            document.documentElement.style.setProperty('--default-low-opacity', '#21c39266');
            break;
          case 'pink':
            document.documentElement.style.setProperty('--default-base', '#f18ab2');
            document.documentElement.style.setProperty('--default-shade', '#e94685');
            document.documentElement.style.setProperty('--default-tint', '#f4a1c1');
            document.documentElement.style.setProperty('--default-light', '#f9cedf');
            document.documentElement.style.setProperty('--default-text', '#FFFFFF');
            document.documentElement.style.setProperty('--default-low-opacity', '#f18ab266');
            break;
          case 'blue':
            document.documentElement.style.setProperty('--default-base', '#3179e8');
            document.documentElement.style.setProperty('--default-shade', '#1555b8');
            document.documentElement.style.setProperty('--default-tint', '#4888eb');
            document.documentElement.style.setProperty('--default-light', '#bbd3f8');
            document.documentElement.style.setProperty('--default-text', '#FFFFFF');
            document.documentElement.style.setProperty('--default-low-opacity', '#3179e866');
            break;
        }
      }
    }
  }
}
