import { EventEmitter, Injectable, Optional } from '@angular/core';
import {
  Auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
  fetchSignInMethodsForEmail
} from '@angular/fire/auth';
import { FirebaseError } from 'firebase/app';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthState, create_user, new_user } from './auth.model';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast/toast.service';

import { PlatformService } from '../../services/platform/platform.service';
// Web
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// Mobile
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { usersList } from 'src/app/views/panda-connect/panda-connect.model';
import { user } from 'src/app/views/profile/profile.model';
import { MenuService } from '../menu/menu.service';
import { ModalController } from '@ionic/angular';
import { ImageSliderModelComponent } from 'src/app/components/image-slider-model/image-slider-model.component';
import { AciScoreDetailsComponent } from 'src/app/components/aci-score-details/aci-score-details.component';

const initialAuthState: AuthState = {
  isLoggedIn: false,
  isEmailVerified: false,
  id: null,
  email: null,
  name: null,
  phone_number: null,
  token: null,
  display_picture: null,
  cover_photo: null,
  role: null,
  age: null,
  is_parent: null
};
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //Loaders
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  readonly isLoading$ = this.isLoading.asObservable();

  private isLoading_users: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  readonly isLoading_users$ = this.isLoading_users.asObservable();

  private openRegisterationFormm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  readonly openRegisterationFormm$ = this.openRegisterationFormm.asObservable();

  
  //Auth State Management
  private readonly _authState = new BehaviorSubject<AuthState>(
    initialAuthState
  );
  readonly auth$ = this._authState.asObservable();

  private users: BehaviorSubject<usersList> = new BehaviorSubject<usersList>(
    null
  );
  readonly users$: Observable<usersList> = this.users.asObservable();

  private verificationSentToUser: BehaviorSubject<user> = new BehaviorSubject<user>(
    null
  );
  readonly verificationSentToUser$: Observable<user> = this.verificationSentToUser.asObservable();


  private is_aci_loading: BehaviorSubject<boolean> =
  new BehaviorSubject<boolean>(false);
readonly is_aci_loading$: Observable<boolean> =
  this.is_aci_loading.asObservable();

  private user_aci: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  readonly user_aci$: Observable<any> = this.user_aci.asObservable();


  forumChatEmitter = new EventEmitter<boolean>();


  openNotifications$: Subject<any> = new Subject<any>();
  notificationPayload$: Subject<any> = new Subject<any>();
  token: string;
  broadcast = new BroadcastChannel('FCMChannel');
  isMobileDevice: boolean;
  loggedOutSession: any;
  age: boolean;

  private show_email_verifications: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly show_email_verifications$: Observable<boolean> = this.show_email_verifications.asObservable();
  constructor(
    @Optional() private auth: Auth,
    private http: HttpClient,
    private toast: ToastService,
    private router: Router,
    private platform: PlatformService,
    private menu: MenuService,
    private modal: ModalController
  ) {
    this.isMobileDevice = this.platform.isMobile();
    this.auth.onIdTokenChanged((user) => {
      if (user) {
        user.getIdToken(true).then((token) => {
          const token_decoded: any = jwt_decode(token);
          let u: AuthState = {
            isLoggedIn: false,
            isEmailVerified: user.emailVerified,
            id: user.uid,
            email: user.email,
            name: user.displayName,
            phone_number: user.phoneNumber,
            token: token,
            display_picture: user.photoURL,
            role: token_decoded.role,
            cover_photo: token_decoded.cover_photo,
            is_admin: token_decoded.is_admin,
            age: token_decoded.age,
            is_parent: token_decoded.is_parent,
          };
          this._authState.next(u);
          this.token = u.token;
          this.age = token_decoded.age;
          this.token && this.requestPermission(u.token);
        });
      }
    });

    this.auth.onAuthStateChanged((user) => {
      if (!user) {
        this.isLoading.next(false);
        this._authState.next(initialAuthState);
        return;
      }

      user.getIdToken(true).then((token) => {
        const token_decoded: any = jwt_decode(token);
        let u: AuthState = {
          isLoggedIn: false,
          isEmailVerified: user.emailVerified,
          id: user.uid,
          email: user.email,
          name: user.displayName,
          phone_number: user.phoneNumber,
          token: token,
          display_picture: user.photoURL,
          role: token_decoded.role,
          cover_photo: token_decoded.cover_photo,
        };
        this._authState.next(u);
        this.token = u.token;
        this.token && this.requestPermission(u.token);
      });
    });
  }

  toggleEmailVerification(state: boolean) {
    this.show_email_verifications.next(state);
  }

  toggleRegisterationForm(state: boolean) {
    this.openRegisterationFormm.next(state);
  } 

  async sendEmailVerifications() {
    this.isLoading.next(true);
    sendEmailVerification(this.loggedOutSession.user)
      .then(async (data) => {
        this.isLoading.next(false);
        this.toast.showToast(
          'Verification sent',
          'We have sent a verification email, please verify your email to login.'
        );
        this.show_email_verifications.next(false);
      })
      .catch(async (err: FirebaseError) => {
        this.isLoading.next(false);
        this.toast.showToast(
          `Error`,
          'There was a problem with sending you an email. Try again later or contact our support team.'
        );
      });
  }

  async sendEmailVerificationsToClubUser(user) {
    this.isLoading.next(true);
    sendPasswordResetEmail(this.auth, user.email)
    .then(async (response) => {
      this.get_users(user.email, 1, 100, true);
      this.isLoading.next(false);
      this.toast.showToast('', 'We have sent an email for verification to your athlete, please ask him/her to verify and set new password to login.', '');
    })
    .catch(async (err: any) => {
      this.isLoading.next(false);
      if (err.code === 'auth/invalid-email') {
        this.toast.showToast(
          '',
          'Your email address has bad email formatting. Please correct and try again.'
        );
      } else if (err.code === 'auth/user-not-found') {
        this.toast.showToast(
          'User not found',
          'Please make sure your email is correct and try again.'
        );
      }
    });
  }

  updateDisplayPicture(display_picture: string) {
    let currentAuthState = this._authState.value;
    currentAuthState.display_picture = display_picture;
    this._authState.next(currentAuthState);
  }

  fetchSignInMethodsForEmail(email, controls, controlKey) {
    let error = {
      emailAlreadyUsed: false,
      emailAlreadyExists: false
    }
    Object.keys(controls).forEach(key => {
      if (controlKey != key) {
        if (email === controls[key].value) {
          error.emailAlreadyUsed = true;
        }
      }
    });
    return new Promise(resolve => {
      fetchSignInMethodsForEmail(this.auth, email).then(res => {
        if (res.length > 0) {
          error.emailAlreadyExists = true;
          resolve(error);
        } else {
          if (!error.emailAlreadyExists && !error.emailAlreadyUsed) {
            resolve(null);
          } else {
            resolve(error);
          }
        }
      });
    });
  }

  emailVerification(email) {
    this.post_unauth(`payment?type=email_verification&email=${email}`, {}, '')
      .pipe(take(1))
      .subscribe(
        async (res) => {
          console.log("set referral response", res);
        },
        async (err) => {
          console.log("set referral error", err)
          this.isLoading.next(false);
        }
      );
  }

   public get_users(search: string, page: number, limit: number, registrationClub :boolean, newCreatedUser = false) {
    this.isLoading_users.next(true);
    if(registrationClub) {
      this.toggleRegisterationForm(false);
    }

    let url = `parent?type=search_users&page=${page}&limit=${limit}`;

    if (search) {
      url += `&search=${search}`;
    }

    if (registrationClub) {
      url += `&registration_club=true`;
    }

    this
      .get(url)
      .pipe(take(1))
      .subscribe(
        (res: usersList) => {
          if(newCreatedUser) {
            this.toggleRegisterationForm(false);
            this.verificationSentToUser.next(res[0]);
          }
          this.users.next(res);
          this.isLoading_users.next(false);
        },
        (err) => {
          console.log('err', err);
          this.users.next(null);
          this.isLoading_users.next(false);
        }
      );
  }

  async registerAccountWithEmailPassword(
    user: create_user,
    is_parent?: boolean,
    child_id?: string,
    club_registeration = false
  ) {
    this.isLoading.next(true);
    createUserWithEmailAndPassword(this.auth, user.email, user.password)
      .then(async (data) => {
        data.user.getIdToken(true).then(async (token) => {
          const token_decoded: any = jwt_decode(token);
          if (!token_decoded.role) {
            let u: new_user = {
              uid: data.user.uid,
              name: user.name,
              dob: user.dob,
              weight: user.weight,
              gender: user.gender,
              email: data.user.email,
              role: user.role,
              is_parent: is_parent || false,
              child_id: child_id || '',
              referral: user.referral
            };
            await this.post('auth?type=new-user', u, token)
              .pipe(take(1))
              .subscribe(
                async (res) => {
                  if (res) {
                    this.loggedOutSession = data;
                    this.isLoading.next(false);
                    await data.user.getIdToken(true);
                    this.sendEmailVerifications();
                    // this.router.navigate(['auth/login'], { replaceUrl: true });
                    // signOut(this.auth);

                    if (club_registeration) {
                      this.get_users(user.email, 1, 100, true);
                    } else {
                      this.router.navigate(['auth/login'], { replaceUrl: true });
                      localStorage.removeItem('age');
                      signOut(this.auth);
                    }
                  }
                },
                async (err) => {
                  this.toast.showToast(
                    'Error',
                    'There was an issue creating this new user. Please try again shortly.',
                    'danger'
                  );
                  this.isLoading.next(false);
                }
              );
          }
        });
      })
      .catch(async (err: FirebaseError) => {
        this.isLoading.next(false);
        if (err.code === 'auth/email-already-in-use') {
          this.toast.showToast(
            'User already exists',
            'A user with this email address already exists, please log in instead of creating a new account.'
          );
        } else {
          this.toast.showToast(
            `Error: ${err.code}`,
            'There was a problem creating your new account. Try again later or contact our support team.'
          );
        }
      });
  }



  calculateAge(birthdate: Date): number {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    localStorage.setItem('age', age.toString());
    return age;
  }


  async openACIModel() {
    const m = await this.modal.create({
      component: AciScoreDetailsComponent,
      animated: true,
      backdropDismiss: true,
      cssClass: 'aciModel'
    });
    m.onDidDismiss().then(data => {
    });
    await m.present();
  }

  async openQuickStartModel(role) {
    const m = await this.modal.create({
      component: ImageSliderModelComponent,
      animated: true,
      backdropDismiss: true,
      cssClass: 'sliderModel'
    });
    m.onDidDismiss().then(data => {
      if (role == "athlete") {
        this.openACIModel();
      }
    });
    await m.present();
  }

  async post_feature_reviewed() {
    await this.post('auth?type=features-reviewed', {}, this.token)
      .pipe(take(1))
      .subscribe(
        async (res) => {
        },
        async (err) => {
        }
      );
  }

  verify_under_age() {
    this.is_aci_loading.next(true);
    this
      .get('reports?type=get_user_aci').pipe(take(1)).subscribe(
        async (res: any) => {
          if (this.calculateAge(res?.is_features_reviewed?.dob) <= 12) {
            this.menu.disableRoutes('age_restrictions');
          }
          //  else {
          //   this.router.navigate(['home'], { replaceUrl: true, queryParams: { fromLogin: true } });
          // }
          // this.user_aci.next(res);
          this.is_aci_loading.next(false);
          // if (role == "athlete") {
          //   if (!res?.is_features_reviewed?.is_features_reviewed) {
          //     this.openQuickStartModel(role);
          //     this.post_feature_reviewed();
          //   } else {
          //     if (this.router.url === '/profile/0?fromLogin=true' || this.router.url === '/profile/0' || this.router.url === '/home' || this.router.url === '/home?fromLogin=true') {
          //       this.openACIModel();
          //     }
          //   }
          // } else if ((!res?.is_features_reviewed?.is_features_reviewed) && fromLogin) {
          //   this.openQuickStartModel(role);
          //   this.post_feature_reviewed();
          // }
        },
        (err) => {
          this.is_aci_loading.next(false);
        }
      );
  }

  public get_user_aci(role) {
    this.is_aci_loading.next(true);
    this
      .get('reports?type=get_user_aci').pipe(take(1)).subscribe(
        async (res: any) => {
          let replaceUrl = localStorage.getItem("club_registeration_link");

          if (this.calculateAge(res?.is_features_reviewed?.dob) <= 12) {
            this.menu.disableRoutes('age_restrictions');
            if(replaceUrl) {
              this.router.navigate([`${replaceUrl}`]);
              this.menu.showMenu();
            } else {
              this.router.navigate(['profile/0'], { replaceUrl: true });
            }
          } else {
            if(replaceUrl) {
              this.router.navigate([`${replaceUrl}`]);
              this.menu.showMenu();
            } else {
              this.router.navigate(['home'], { replaceUrl: true });
            }
          }
          this.user_aci.next(res);
          this.is_aci_loading.next(false);
          // if (role == "athlete") {
            if (!res?.is_features_reviewed?.is_features_reviewed) {
              this.openQuickStartModel(role);
              this.post_feature_reviewed();
            } else {
              // if (this.router.url === '/profile/0?fromLogin=true' || this.router.url === '/profile/0' || this.router.url === '/home' || this.router.url === '/home?fromLogin=true') {
                this.openACIModel();
              // }
            }
          // } else if ((!res?.is_features_reviewed?.is_features_reviewed) && fromLogin) {
          //   this.openQuickStartModel(role);
          //   this.post_feature_reviewed();
          // }
        },
        (err) => {
          this.is_aci_loading.next(false);
        }
      );
  }

  async loginWithEmailPassword(email: string, password: string) {
    this.isLoading.next(true);
    signInWithEmailAndPassword(this.auth, email, password)
      .then((user: any) => {    
        this.token = user.user.accessToken;  
        this.isLoading.next(false);
        if(!user.user.emailVerified) {
          this.toast.showToast(
            'Email verification required',
            'Your email is not verified, please verify it to continue.'
          );
          this.loggedOutSession = user;
          signOut(this.auth);
          this.show_email_verifications.next(true);
        } else {
          const token_decoded: any = jwt_decode(user.user.accessToken);
            // this.get_user_aci(token_decoded.role);
            if(token_decoded.age) {
              // this.router.navigate(['home'], { replaceUrl: true});
              this.router.navigate(['home'], { replaceUrl: true, queryParams: { fromLogin: true}});
            } else {
              this.router.navigate(['profile/0'], { replaceUrl: true, queryParams: { fromLogin: true}});
              // this.router.navigate(['profile/0'], { replaceUrl: true});
            }
        }
      })
      .catch(async (err: FirebaseError) => {
        this.isLoading.next(false);
        if (err.code === 'auth/wrong-password') {
          this.toast.showToast(
            'Wrong Password',
            'Password provided does not match with the identity already registered. Please add the valid password and try again.'
          );
        } else if (err.code === 'auth/user-not-found') {
          this.toast.showToast(
            'No user found',
            'With provided credentials, we could not find any user registered. Please sign up your new account and try again.'
          );
        } else if (err.code === 'auth/user-disabled') {
          this.toast.showToast(
            'Account Blocked',
            'Your account is blocked by PANDAStronger moderation team. Please contact support to verify and enable it.'
          );
        }
      });
  }

  async logout() {
    localStorage.removeItem('age');
    this.updatePushNotificationId('', this.token);
    await signOut(this.auth);
    localStorage.removeItem('club_registeration_link');
  }

  private post_unauth(url: string, body: any, token: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    return this.http.post(environment.unauth_api_url + url, body, {
      headers: new HttpHeaders(headers),
    });
  }

  private post(url: string, body: any, token: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    return this.http.post(environment.api_url + url, body, {
      headers: new HttpHeaders(headers),
    });
  }

  get(url: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.token,
    };
    return this.http.get(environment.api_url + url, {
      headers: new HttpHeaders(headers),
    });
  }

  async forgotPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email)
      .then(async (response) => {
        // console.log('response', response);
        this.toast.showToast('', 'Please Check your email for reset link.', '');
      })
      .catch(async (err: any) => {
        if (err.code === 'auth/invalid-email') {
          this.toast.showToast(
            '',
            'Your email address has bad email formatting. Please correct and try again.'
          );
        } else if (err.code === 'auth/user-not-found') {
          this.toast.showToast(
            'User not found',
            'Please make sure your email is correct and try again.'
          );
        }
      });
  }

  // Notification Utilities
  requestPermission(token) {
    if (this.isMobileDevice) {
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          PushNotifications.addListener(
            'registration',
            (currentToken: Token) => {
              this.updatePushNotificationId(currentToken.value, token);
            }
          );
        } else {
          PushNotifications.addListener('registrationError', (error: any) => {
            console.log('Error on registration: ', JSON.stringify(error));
          });
          // Show some error
        }
      });
    } else {
      const messaging = getMessaging();

      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          getToken(messaging, { vapidKey: environment.firebase.vapidKey })
            .then((currentToken) => {
              if (currentToken) {
                if (token) {
                  this.updatePushNotificationId(currentToken, token);
                }
              } else {
                console.log('Please request permission to generate token.');
              }
            })
            .catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
            });
        }
      });
    }
  }

  closeNotifications() {
    this.openNotifications$.next(null);
  }

  goToGroups(data) {
    this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
      queryParams: { admin_id: data.admin_id, group: data.group, current_user_role: data.role, tab: 'forum', stopQuickMenue: true },
    });
  }

  goToGroupsParent(data) {
    this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
      queryParams: { admin_id: data.admin_id, group: data.group, current_user_role: data.role, tab: 'parent_forum', stopQuickMenue: true },
    });
  }

  goToGroupsChat(data) {
    this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
      queryParams: { admin_id: data.admin_id, group: data.chat_group_name, current_user_role: data.role, tab: 'group_chat', channel_id: data.teaming_up_channel_id, stopQuickMenue: true },
    });
  }

  goToGroupNotices(data) {
    this.router.navigate([`teaming-up/group/${data.teamingUP_id}`], {
      queryParams: { admin_id: data.admin_id, group: data.group, current_user_role: this.auth.currentUser, tab: 'notice', stopQuickMenue: true },
    });
  }

  listen() {
    if (this.isMobileDevice) {
      let payload: any = {
        notification: {
          title: '',
        },
        data: {},
      };
      PushNotifications.addListener(
        'pushNotificationReceived',
        (pushNotificationPayload: PushNotificationSchema) => {
          payload.notification.title = pushNotificationPayload.title;
          payload.data = pushNotificationPayload.data;
          this.notificationPayload$.next(payload);
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: any) => {
          if (notification?.notification?.data?.tab === 'forum') {
            // if (window.location.pathname.split('/')[2] === 'group') {
            //   this.router.navigate(['/teaming-up'], { queryParams: { stopQuickMenue: true} })
            // }
            this.router.navigate(['/home'], { queryParams: { stopQuickMenue: true} })
            setTimeout(() => {
              this.goToGroups(notification?.notification?.data);
            }, 3000);
          } else if (notification?.notification?.data?.tab === 'parent_forum') {
            // if (window.location.pathname.split('/')[2] === 'group') {
            //   this.router.navigate(['/teaming-up'], { queryParams: { stopQuickMenue: true} })
            // }
            this.router.navigate(['/home'], { queryParams: { stopQuickMenue: true} })
            setTimeout(() => {
              this.goToGroupsParent(notification?.notification?.data);
            }, 3000);
          }
          else if (notification?.notification?.data?.tab === 'group_chat') { // for new group chat
            // if (window.location.pathname.split('/')[2] === 'group') {
            //   this.router.navigate(['/teaming-up'], { queryParams: { stopQuickMenue: true} })
            // }
            this.router.navigate(['/home'], { queryParams: { stopQuickMenue: true} })
            setTimeout(() => {
              this.goToGroupsChat(notification?.notification?.data);
            }, 3000);
          } else if (notification?.notification?.data?.tab === 'notice') {
            // if (window.location.pathname.split('/')[2] === 'group') {
            //   this.router.navigate(['/teaming-up'], { queryParams: { stopQuickMenue: true} })
            // }
            this.router.navigate(['/home'], { queryParams: { stopQuickMenue: true} })
            setTimeout(() => {
              this.goToGroupNotices(notification?.notification?.data);
            }, 3000);
          } else if (notification?.notification?.data?.redirect_to === 'notification') {
            this.openNotifications$.next({ open: true });
            this.router.navigate(['profile/0'], { replaceUrl: true, queryParams: { stopQuickMenue: true} });
            this.router.navigate(['home'], { replaceUrl: true, queryParams: { stopQuickMenue: true} });
          } else if (notification?.notification?.data?.redirect_to === 'home') {
            this.router.navigate(['home'], { replaceUrl: true, queryParams: { stopQuickMenue: true} });
          } else if (notification?.notification?.data?.redirect_to === 'calendar') {
            this.router.navigate(['/calendar'], { replaceUrl: true, queryParams: { stopQuickMenue: true} });
          } else if (notification?.notification?.data?.redirect_to === 'chat') {
            this.router.navigate(['/chat'], { replaceUrl: true, queryParams: { stopQuickMenue: true} });
          } else if (notification?.notification?.data?.redirect_to === 'profile-workout') {
            // if (window.location.pathname.split('/')[1] === 'profile') {
            //   this.router.navigate(['/home'], {queryParams: { stopQuickMenue: true} });
            // }

            this.router.navigate(['/home'], { queryParams: { stopQuickMenue: true} })
            setTimeout(() => {
              this.redirectToUserWorkouts(notification?.notification?.data?.user_id);
            }, 3000);
          } else if (notification?.notification?.data?.redirect_to === 'challenge_created') {
            this.router.navigate(['/challenge'], { replaceUrl: true, queryParams: { stopQuickMenue: true} });
          } 
        }
      );

    } else {
      const messaging = getMessaging();

      // Background
      this.broadcast.onmessage = (event) => {
        console.log(event);
        this.notificationPayload$.next(event.data);
      };

      // Foreground
      onMessage(messaging, (payload) => {
        console.log(payload);
        this.notificationPayload$.next(payload);
      });
    }
  }

  redirectToUserWorkouts(id) {
    this.router.navigate([`/profile/${id}`], {
      queryParams: { workout: true, stopQuickMenue: true },
    });
  }

  updatePushNotificationId(push_notification_id, token?) {
    // console.log('push_notification_id', push_notification_id);
    let url = `user?type=push_notification_id`;
    this.post(
      url,
      {
        id: push_notification_id,
      },
      token
    ).pipe(take(1)).subscribe(
      (response) => {
      },
      (err) => {
        console.log('err', err);
      }
    );
  }

  async refreshToken() {
    const user = this.auth.currentUser;
    const token = await user.getIdToken(true);

    let currentAuthState = this._authState.value;
    currentAuthState.token = token;
    this._authState.next(currentAuthState);
    // console.log('token', token);
  }
}
