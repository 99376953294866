import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { NewActivityComponent } from './new-activity.component';
import { CustomPipesModule } from '../../../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from '@ngneat/input-mask';

@NgModule({
  imports: [CommonModule, CustomPipesModule, FormsModule, ReactiveFormsModule, InputMaskModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NewActivityComponent],
  exports: [NewActivityComponent],
})
export class NewActivityComponentModule {}