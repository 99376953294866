import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import {
  canActivate,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectAuthorizedToHome = () => {
  let age = JSON.parse(localStorage.getItem("age"));
  let currentRoute = window.location.href.split('/').splice(3, window.location.href.split('/')?.length - 1).join('/');
  let club_registeration_link = window.location.href.split('/').splice(3, window.location.href.split('/')?.length - 1).join('/');
  let app_first_route = window.location.href.split('/');

  if (currentRoute != '') {
    if (!age && app_first_route[4] == 'registration-club') {
      localStorage.setItem("club_registeration_link", club_registeration_link)
    }
    return redirectLoggedInTo([currentRoute]);
  } else {
    return redirectLoggedInTo(['home']);
  }
};

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./views/auth/auth.module').then( m => m.AuthPageModule),
    ...canActivate(redirectAuthorizedToHome)
  },
  {
    path: 'home',
    loadChildren: () => import('./views/home/home.module').then( m => m.HomePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'panda/:home',
    loadChildren: () => import('./views/home/home.module').then( m => m.HomePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./views/profile/profile.module').then( m => m.ProfilePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'undefined',
    loadChildren: () => import('./views/profile/profile.module').then( m => m.ProfilePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'settings',
    loadChildren: () => import('./views/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'teaming-up',
    loadChildren: () => import('./views/teaming-up/teaming-up.module').then( m => m.TeamingUpPageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./views/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'panda-connect',
    loadChildren: () => import('./views/panda-connect/panda-connect.module').then( m => m.PandaConnectPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./views/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'email-coach',
    loadChildren: () => import('./views/email-coach/email-coach.module').then( m => m.EmailCoachPageModule)
  },
  {
    path: 'workouts',
    loadChildren: () => import('./views/workouts/workouts.module').then( m => m.WorkoutsPageModule)
  },
  {
    path: 'comparison',
    loadChildren: () => import('./views/comparison/comparison.module').then( m => m.ComparisonPageModule)
  },
  {
    path: 'quick-workout',
    loadChildren: () => import('./views/quick-workout/quick-workout.module').then( m => m.QuickWorkoutPageModule)
  },
  {
    path: 'challenge',
    loadChildren: () => import('./views/challenge/challenge.module').then( m => m.ChallengePageModule)
  },
  {
    path: 'aci-comparison',
    loadChildren: () => import('./views/aci-comparison/aci-comparison.module').then( m => m.AciComparisonPageModule)
  },
  {
    path: 'panda-club',
    loadChildren: () => import('./views/panda-club/panda-club.module').then( m => m.PandaClubPageModule)
  },
  {
    path: 'reverse-lookups',
    loadChildren: () => import('./views/reverse-lookups/reverse-lookups.module').then( m => m.ReverseLookupsPageModule)
  },  {
    path: 'new-quick-workout',
    loadChildren: () => import('./components/new-quick-workout/new-quick-workout.module').then( m => m.NewQuickWorkoutPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
