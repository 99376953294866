import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { WorkoutDetailComponent } from './workout-detail.component';
import { CustomPipesModule } from '../../pipes/pipes.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, CustomPipesModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [WorkoutDetailComponent],
  exports: [WorkoutDetailComponent],
})
export class WorkoutDetailModule {}